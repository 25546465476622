import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";

const TableBox = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.borderColor};
`;
const DevideView = styled.div`
  display: flex;
  padding: 12px 15px;
  justify-content: center;
  align-items: center;
  &:first-child {
    width: 5%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 70%;
    justify-content: start;
  }
  &:last-child {
    width: 10%;
  }
`;
const TebleText = styled.span`
  color: ${colors.darkestGray};
`;
const TebleAText = styled.span`
  color: ${colors.darkestGray};
  &:hover {
    color: ${colors.activeBlue};
  }
  &:active {
    opacity: 0.8;
  }
`;
const DeleteButton = styled.button`
  width: 66px;
  height: 33px;
  color: ${colors.whiteColor};
  border-radius: 4px;
  border: none;
  background: ${colors.lightOrange};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

function TableBodyData({
  number = 0,
  createdAt = "20210302",
  url = "없음",
  id,
  onPress = () => null,
}) {
  const createDate = new Date(parseInt(createdAt))
    .toLocaleDateString()
    .split(".");
  return (
    <TableBox>
      <DevideView>
        <TebleText>{number}</TebleText>
      </DevideView>
      <DevideView>
        <TebleText>
          {createDate[0]}. {createDate[1]}. {createDate[2]}
        </TebleText>
      </DevideView>
      <DevideView>
        <a href={url} target="_blank">
          <TebleAText>{url}</TebleAText>
        </a>
      </DevideView>
      <DevideView>
        <TebleText>
          <DeleteButton onClick={() => onPress(id)}>삭제</DeleteButton>
        </TebleText>
      </DevideView>
    </TableBox>
  );
}

export default TableBodyData;
