import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import CheckBox from "../../share/CheckBox";
import { IoMdSquareOutline, IoMdSquare } from "react-icons/io";

const TableWrap = styled.div`
  width: 100%;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.borderColor};
`;
const DevideView = styled.div`
  width: calc(100% / 10);
  display: flex;
  justify-content: center;
`;
const TableText = styled.span`
  color: ${colors.darkestBlack};
`;
const TableTextBox = styled.div``;
const TableTextBlock = styled.div`
  height: 20px;
  text-align: center;
  color: ${colors.darkestBlack};
  white-space: nowrap;
`;

const ModalOpenBlock = styled.div`
  height: 20px;
  text-align: center;
  font-size: 16px;
  color: ${colors.darkestBlack};
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${colors.activeBlue};
    font-weight: bold;
  }
`;
const SMSView = styled.button`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
  outline: null;
  &:focus {
    outline-width: 0;
  }
`;

function TableBodyDataDone({
  id,
  createdAt,
  managementNumber,
  modalOpen = () => null,
  setSelectSalesPost = () => null,
  seller = {
    phoneNumber: "",
  },
  biddings = [],
  status,
  taxBill,
  carModel,
  commissionConfirm,
  onClickFeesCheck = () => null,
  onClickTaxBillCheck = () => null,
  onClickSmsCheck = () => null,
  onClickDeleteCheck,
  data,
}) {
  console.log("taxBill", taxBill);
  const [smsChecked, setSmsCheck] = useState(false);
  const [taxCheck, setTaxCheck] = useState(taxBill);
  const [commissionCheck, setCommissionCheck] = useState(commissionConfirm);
  const [deleteChecked, setDeletedCheck] = useState(false);
  const createDate = new Date(parseInt(createdAt))
    .toLocaleDateString()
    .split(".");

  const koreanState = (state) => {
    switch (state) {
      case "observation":
        return "유찰";
      case "register":
        return "등록중";
      case "complete":
        return "완료";
      case "trading":
        return "경매중";
      default:
        return "";
    }
  };
  return (
    <TableWrap>
      <DevideView>
        <TableText>{managementNumber}</TableText>
      </DevideView>
      <DevideView>
        <TableText>
          {createDate[0]}. {createDate[1]}. {createDate[2]}
        </TableText>
      </DevideView>
      <DevideView>
        <TableTextBox
          onClick={() => {
            setSelectSalesPost(data);
            modalOpen();
          }}
        >
          <ModalOpenBlock>{seller.name ? seller.name : "없음"} ({ carModel || "없음"})</ModalOpenBlock>
          <ModalOpenBlock>
            {seller.phoneNumber
              ? seller.phoneNumber
                  .replace(/[^0-9]/g, "")
                  .replace(
                    /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
                    "$1-$2-$3"
                  )
                  .replace("--", "-")
              : "없음"}
          </ModalOpenBlock>
        </TableTextBox>
      </DevideView>
      <DevideView>
        <TableTextBox>
          {biddings && biddings.length === 0 && (
            <>
              <TableTextBlock>견적 X</TableTextBlock>
            </>
          )}
          {biddings &&
            biddings.length === 1 &&
            biddings.buyer &&
            biddings.buyer.length !== 0 && (
              <>
                <TableTextBlock>{biddings.buyer.name}</TableTextBlock>
                <TableTextBlock>{biddings.buyer.phoneNumber}</TableTextBlock>
              </>
            )}
          {biddings && biddings.length >= 1 && (
            <TableTextBox>
              <TableTextBlock>{biddings.length}명</TableTextBlock>
            </TableTextBox>
          )}
        </TableTextBox>
      </DevideView>
      <DevideView>
        <TableTextBlock>{koreanState(status)}</TableTextBlock>
      </DevideView>
      <DevideView>
        <TableText>
          {biddings.length > 0
            ? biddings
            .map((b) => b.price)
            .reduce((previous, current) => {
              return previous > current ? previous : current;
            })
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "만원"
            : "-"}
        </TableText>
      </DevideView>
      <DevideView>
        {taxBill ? <TableText>O</TableText> : <TableText>X</TableText>}
      </DevideView>
      {/*<DevideView>*/}
      {/*  {commissionCheck ? (*/}
      {/*    <SMSView*/}
      {/*      onClick={() => {*/}
      {/*        onClickFeesCheck(id);*/}
      {/*        setCommissionCheck(!commissionCheck);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <IoMdSquare size="18" color={colors.activePurple} />*/}
      {/*    </SMSView>*/}
      {/*  ) : (*/}
      {/*    <SMSView*/}
      {/*      onClick={() => {*/}
      {/*        onClickFeesCheck(id);*/}
      {/*        setCommissionCheck(!commissionCheck);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <IoMdSquareOutline size="18" color={colors.activePurple} />*/}
      {/*    </SMSView>*/}
      {/*  )}*/}
      {/*</DevideView>*/}
      {/*<DevideView>*/}
      {/*  {taxCheck ? (*/}
      {/*    <SMSView*/}
      {/*      onClick={() => {*/}
      {/*        onClickTaxBillCheck(id);*/}
      {/*        setTaxCheck(!taxCheck);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <IoMdSquare size="18" color={colors.activePurple} />*/}
      {/*    </SMSView>*/}
      {/*  ) : (*/}
      {/*    <SMSView*/}
      {/*      onClick={() => {*/}
      {/*        onClickTaxBillCheck(id);*/}
      {/*        setTaxCheck(!taxCheck);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <IoMdSquareOutline size="18" color={colors.activePurple} />*/}
      {/*    </SMSView>*/}
      {/*  )}*/}
      {/*</DevideView>*/}
      {/*<DevideView>*/}
      {/*  {smsChecked ? (*/}
      {/*    <SMSView*/}
      {/*      onClick={() => {*/}
      {/*        onClickSmsCheck(smsChecked, seller.phoneNumber);*/}
      {/*        setSmsCheck(!smsChecked);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <IoMdSquare size="18" color={colors.activeBlue} />*/}
      {/*    </SMSView>*/}
      {/*  ) : (*/}
      {/*    <SMSView*/}
      {/*      onClick={() => {*/}
      {/*        onClickSmsCheck(smsChecked, seller.phoneNumber);*/}
      {/*        setSmsCheck(!smsChecked);*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <IoMdSquareOutline size="18" color={colors.activeBlue} />*/}
      {/*    </SMSView>*/}
      {/*  )}*/}
      {/*</DevideView>*/}
      <DevideView>
        {deleteChecked ? (
          <SMSView
            onClick={() => {
              onClickDeleteCheck(deleteChecked, id);
              setDeletedCheck(!deleteChecked);
            }}
          >
            <IoMdSquare size="18" color={colors.activeBlue} />
          </SMSView>
        ) : (
          <SMSView
            onClick={() => {
              onClickDeleteCheck(deleteChecked, id);
              setDeletedCheck(!deleteChecked);
            }}
          >
            <IoMdSquareOutline size="18" color={colors.activeBlue} />
          </SMSView>
        )}
      </DevideView>
    </TableWrap>
  );
}

export default React.memo(TableBodyDataDone);
