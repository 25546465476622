import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";

const Wrap = styled.div`
  min-width: 1200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.lightGrayColor};
`;
const LoginBox = styled.div`
  width: 372px;
  height: 351px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.whiteColor};
  border-radius: 19px;
`;
const Title = styled.div`
  width: 120px;
  color: ${colors.skyBlue};
  font: bold 26px "SF Pro Display";
  margin: 41px 0 20px;
`;
const Input = styled.input`
  width: 294px;
  height: 48px;
  border: 1px solid ${colors.borderColor};
  border-radius: 12px;
  padding: 0 20px;
  margin-top: 10px;
`;
const LoginButton = styled.button`
  width: 294px;
  height: 39px;
  border-radius: 20px;
  color: ${colors.whiteColor};
  background-color: ${colors.skyBlue};
  border: none;
  margin-top: 29px;
  cursor: pointer;
  outline: none;
  &:active {
    opacity: 0.3;
    /* transition: 0.2s; */
  }
`;

function LoginPresenter({ idInput, pwInput, onSubmit }) {
  return (
    <Wrap>
      <LoginBox>
        <Title>수출 옥션</Title>
        <Input name="userId" placeholder="아이디" {...idInput} />
        <Input
          name="userPwd"
          placeholder="비밀번호"
          type="password"
          {...pwInput}
        />
        <LoginButton onClick={() => onSubmit()}>로그인</LoginButton>
      </LoginBox>
    </Wrap>
  );
}

export default React.memo(LoginPresenter);
