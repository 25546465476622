import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import ContentHead from "../../components/share/ContentHead";
import ContentTop from "../../components/share/ContentTop";
import AuctionTotal from "../../components/feature/Auction/AuctionTotal";
import SmsButton from "../../components/share/SmsButton";
import AuctionIng from "../../components/feature/Auction/AuctionIng";
import AuctionFailed from "../../components/feature/Auction/AuctionFailed";
import AuctionDone from "../../components/feature/Auction/AcutionDone";
import BiddingDetailModal from "../../components/feature/Auction/BiddingDetailModal";
import ContentsHeader from "../../components/share/ContentsHeader";
import Loader from "../../components/share/Loader";
import UserMessageModal from "../../components/feature/User/UserMessageModal";
import Input from "../../components/share/Input";

const AuctionWrap = styled.div`
  width: 100%;
  min-width: 1200px;
  height: 100%;
  padding-left: 240px;
  background-color: ${colors.emptyBackgroundColor};
  padding-bottom: 20px;
`;
const AuctionContent = styled.div`
  margin: 30px;
  padding: 30px;
  background-color: ${colors.whiteColor};
`;
const TableTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TableTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkestBlack};
`;
const TableHeader = styled.div`
  display: flex;
  margin-top: 20px;
`;

const SearchBlock = styled.div`
  padding-left: 32px;
  padding-top: 20px;
  display: flex;
`;
const Button = styled.button`
  margin-left: 12px;
  width: 80px;
  height: 40px;
  font-size: 16px;
  line-height: 16px;
  border: none;
  border-radius: 4px;
  text-align: center;
  color: ${colors.whiteColor};
  ${(props) =>
    props.toggleSearchUser
      ? `background-color: ${colors.activePurple};`
      : `background-color: ${colors.activeBlue};`}
  outline: none;
  &:active {
    opacity: 0.8;
  }
  cursor: pointer;
`;

function SearchPresenter({
  onSmsCheck,
  smsCheck,
  state,
  detailView,
  detailViewClick,
  modal,
  modalOpen,
  modalClose,
  onClickSmsCheck = () => null,
  sendMessage = () => null,
  messageModalOpen,
  smsList,
  messageInput,
  messageModal,
  messageModalClose,
  searchInput,
  toggleSearchUser,
  setToggleSearchUser,
  data,
  userData,
  selectSalesPost,
  setSelectSalesPost,
}) {
  return (
    <AuctionWrap>
      <ContentHead
        title="검색"
        subTitle="매물 / 유저"
        bgColor={colors.activeBlue}
        subText="검색하기"
        auctionVisible={false}
        totalUsers="31"
      />
      <SearchBlock>
        <Input searchInput={searchInput} />
        <Button
          onClick={() => setToggleSearchUser(!toggleSearchUser)}
          toggleSearchUser={toggleSearchUser}
        >
          {toggleSearchUser ? "유저" : "경매"}
        </Button>
      </SearchBlock>
      <AuctionContent>
        <TableTitleBox>
          <TableTitle>검색결과</TableTitle>
          <SmsButton messageModalOpen={messageModalOpen} />
        </TableTitleBox>
        <TableHeader>
          {!toggleSearchUser ? (
            <ContentsHeader
              headerTitle={[
                "경매관리번호",
                "경매일자",
                "판매자",
                "매입자",
                "상태",
                "매입금액",
                "명의이전",
                "수수료",
                "세금계산서",
                "문자",
              ]}
            />
          ) : (
            <ContentsHeader
              headerTitle={[
                "구분",
                "가입일",
                "이름",
                "휴대폰번호",
                "사원증/사업자",
                "상태",
                "횟수",
                "인증",
                "문자",
              ]}
            />
          )}
        </TableHeader>

        {!toggleSearchUser ? (
          <>
            {data &&
            data.searchSalesPost &&
            data.searchSalesPost.salesPosts.length !== 0 ? (
              data.searchSalesPost.salesPosts.map((props, index) => (
                <AuctionTotal
                  onSmsCheck={onSmsCheck}
                  smsCheck={smsCheck}
                  modal={modal}
                  modalOpen={modalOpen}
                  modalClose={modalClose}
                  data={props}
                  contentNumber={index + 1}
                  onClickSmsCheck={onClickSmsCheck}
                  key={"SearchSalesPost" + index}
                  setSelectSalesPost={setSelectSalesPost}
                />
              ))
            ) : (
              <Loader />
            )}
          </>
        ) : null}
        {toggleSearchUser && (
          <>
            {userData &&
            userData.searchUser &&
            userData.searchUser.users.length !== 0 ? (
              userData.searchUser.users.map((props, index) => (
                <AuctionTotal
                  onSmsCheck={onSmsCheck}
                  smsCheck={smsCheck}
                  modal={modal}
                  modalOpen={modalOpen}
                  modalClose={modalClose}
                  data={props}
                  contentNumber={index + 1}
                  onClickSmsCheck={onClickSmsCheck}
                  key={"userDataTable" + index}
                  toggleSearchUser={toggleSearchUser}
                />
              ))
            ) : (
              <Loader />
            )}
          </>
        )}
      </AuctionContent>

      {/* Modal */}
      <BiddingDetailModal
        state={state}
        detailView={detailView}
        detailViewClick={detailViewClick}
        modal={modal}
        modalClose={modalClose}
        selectSalesPost={selectSalesPost}
      />
      <UserMessageModal
        messageModal={messageModal}
        messageModalClose={messageModalClose}
        smsList={smsList}
        messageInput={messageInput}
        sendMessage={sendMessage}
      />
    </AuctionWrap>
  );
}

export default React.memo(SearchPresenter);
