import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";

const InputBox = styled.div`
  width: 365px;
  height: 45px;
  display: flex;
  background: ${colors.whiteColor};
  & + & {
    margin-top: 22px;
  }
`;
const Input = styled.input`
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  &::placeholder {
    color: ${colors.grayColor};
  }
`;
const Button = styled.button`
  width: 120px;
  height: 45px;
  color: ${colors.whiteColor};
  border: none;
  background-color: ${colors.activeBlue};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

function NoticeInput({
  placeholder,
  buttonText,
  hooksValue = "",
  onPress = () => null,
}) {
  return (
    <InputBox>
      <Input placeholder={placeholder} {...hooksValue} />
      <Button onClick={onPress}>{buttonText}</Button>
    </InputBox>
  );
}

export default NoticeInput;
