import React from "react";
import styled from "styled-components";
import TableBodyData from "./TableBodyData";
import TableUserBodyData from "../User/TableBodyData";

const AuctionTotalBox = styled.div``;

function AuctionTotal({
  onSmsCheck,
  smsCheck,
  modalOpen,
  data = {},
  contentNumber,
  onClickSmsCheck,
  toggleSearchUser,
  setSelectSalesPost = () => null,
  smsAllCheck = false,
  deleteCheck = false,
  setDeleteCheck = () => null,
  onClickDeleteCheck = () => null,
}) {
  return (
    <AuctionTotalBox>
      {toggleSearchUser ? (
        <TableUserBodyData
          onSmsCheck={onSmsCheck}
          smsCheck={smsCheck}
          modalOpen={modalOpen}
          contentNumber={contentNumber}
          onClickSmsCheck={onClickSmsCheck}
          key={data.id}
          setSelectSalesPost={setSelectSalesPost}
          data={data}
          smsAllCheck={smsAllCheck}
          {...data}
        />
      ) : (
        <TableBodyData
          onSmsCheck={onSmsCheck}
          deleteCheck={deleteCheck}
          setDeleteCheck={setDeleteCheck}
          smsAllCheck={smsAllCheck}
          smsCheck={smsCheck}
          modalOpen={modalOpen}
          contentNumber={contentNumber}
          onClickSmsCheck={onClickSmsCheck}
          setSelectSalesPost={setSelectSalesPost}
          onClickDeleteCheck={onClickDeleteCheck}
          data={data}
          key={data.id}
          {...data}
        />
      )}
    </AuctionTotalBox>
  );
}

export default AuctionTotal;
