import React, {useState} from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import DetailInfo from "./DetailInfo";
import {toast} from "react-toastify";
import {useMutation} from "@apollo/client";
import {TRADE_COMPLETE} from "../../../graphql/auction/mutation";

const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
`;
const ModalBlock = styled.div`
  min-width: 1280px;
  height: ${(props) => (props.detailView ? "900px" : "700px")};
  padding: 35px;
  background: ${colors.whiteColor};
  border-radius: 28px;
  overflow: hidden;
  overflow-y: scroll;
  transition: 0.3s;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`;
const ModalTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkestBlack};
`;
const CloseButton = styled.div`
  font-size: 18px;
  color: ${colors.inactiveColor};
  cursor: pointer;
`;
const ModalContent = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  border-bottom: ${colors.darkBorderColor};
`;

function BiddingDetailModal({
  modal,
  modalClose,
  state,
  detailView,
  detailViewClick,
  selectSalesPost,
  handleObservation = () => null,
  }) {
  const [selectBidding, setSelectBidding] = useState(null);
  const [tradeComplete] = useMutation(TRADE_COMPLETE);

  if (!modal) return null;
  const handleSelectBidding = (bidId) => {
    setSelectBidding(bidId)
  };

  const handleTradeComplete = async (salesPostId, biddingId, nameDocumentUrl, discountReason) => {
    try {
      const result = await tradeComplete({
        variables: {
          salesPostId,
          biddingId,
          nameDocumentUrl,
          discountReason
        },
      });
      if (result) {
        toast("거래완료 변경되었습니다.");
      }
    } catch (e) {
      toast.error(e);
      console.log(e);
    }
  };
  return (
    <DarkBackground>
      <ModalBlock detailView={detailView}>
        <ModalHeader>
          <ModalTitle>매물 상세 정보</ModalTitle>
          <CloseButton onClick={modalClose}>닫기</CloseButton>
        </ModalHeader>

        <ModalContent>
          <DetailInfo
            state={state}
            detailView={detailView}
            detailViewClick={detailViewClick}
            {...selectSalesPost}
            handleObservation={handleObservation}
            handleTradeComplete={handleTradeComplete}
            modalClose={modalClose}
            selectBidding={selectBidding}
            handleSelectBidding={handleSelectBidding}
          />
        </ModalContent>
      </ModalBlock>
    </DarkBackground>
  );
}

export default BiddingDetailModal;
