import { ApolloClient, InMemoryCache } from "@apollo/client";
import { defaults, resolvers } from "./LocalState";
import ServerIP from "./ServerIP";

export default new ApolloClient({
  uri: ServerIP,
  clientState: {
    defaults,
    resolvers,
  },
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});
