import { gql } from "@apollo/client";

export const EXCEL_CONVERT = gql`
  query excelConvert($tableName: TableName) {
    excelConvert(tableName: $tableName)
  }
`;

export const TODAY_TOTAL_SALES = gql`
  query todayTotalSales {
    todayTotalSales {
      totalSales
      contents {
        salesPosts {
          id
          managementNumber
          carModel
          carYear
        }
        price
        seller
        buyer
      }
    }
  }
`;

export const ADMIN_ALL_USER_COUNT = gql`
  query adminAllUserCount {
    adminAllUserCount {
      allUserCount
      adminConfirmUserCount
      sellerCount
      buyerCount
      stopUserCount
    }
  }
`;

export const ADMIN_ALL_SALES_POST_COUNT = gql`
  query adminAllSalesPostCount {
    adminAllSalesPostCount {
      allSalesPostCount
      registerCount
      completeCount
      tradingCount
      cancelCount
      observationCount
    }
  }
`;

export const ADMIN_SEE_ALL_USER = gql`
  query adminSeeUser($userId: Int) {
    adminSeeUser(userId: $userId) {
      postsCount {
        all
        register
        trading
        observation
        complete
      }
      biddingsCount {
        all
        register
        trading
        observation
        complete
        cancel
      }
      buyerCancelCount {
        accidentDifferent
        gradeDifferent
        estimateMistake
        discountDepreciation
        scheduleDifferent
        carStatusBad
        ohter
      }
      sellerCancelCount {
        contactFail
        excessiveDiscount
        estimateMistake
        differentInformation
        buyerCancelRequest
        other
      }
    }
  }
`;

export const SEE_FULL_SALES_POST = gql`
  query seeFullSalesPost($take: Int, $cursor: Int, $status: Status) {
    seeFullSalesPost(take: $take, cursor: $cursor, status: $status) {
      salesPosts {
        id
        discountReason
        createdAt
        updatedAt
        managementNumber
        manufacturer
        carModel
        carYear
        carColor
        fuelType
        distance
        carGrade
        carNumber
        transmission
        nameDocumentUrl
        area
        files {
          id
          url
        }
        option {
          id
          optionSmartKey
          option4WD
          optionSunLoop
          optionNavigation
          optionAroundView
          optionBrownSeat
        }
        description
        completedDate
        status
        commissionConfirm
        taxBill

        seller {
          id
          name
          phoneNumber
          role
          stopedCount
          stoped
        }
        auctionDeadline
        biddingCount
        biddings {
          id
          status
          createdAt
          price
          sellerCancellationReasons {
            contactFail
            excessiveDiscount
            estimateMistake
            differentInformation
            buyerCancelRequest
            other
          }
          buyerCancellationReasons {
            accidentDifferent
            gradeDifferent
            estimateMistake
            discountDepreciation
            scheduleDifferent
            carStatusBad
            ohter
          }
          buyer {
            id
            name
            phoneNumber
            company
          }
          condition
          feedbackScore
          feedbackReason
        }
      }
      salesPostCount
      preCursor
    }
  }
`;
