import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import ContentHead from "../../components/share/ContentHead";
import ContentTop from "../../components/feature/Auction/ContentTop";
import AuctionTotal from "../../components/feature/Auction/AuctionTotal";
import SmsButton from "../../components/share/SmsButton";
import AuctionDone from "../../components/feature/Auction/AcutionDone";
import BiddingDetailModal from "../../components/feature/Auction/BiddingDetailModal";
import ContentsHeader from "../../components/share/ContentsHeader";
import Loader from "../../components/share/Loader";
import UserMessageModal from "../../components/feature/User/UserMessageModal";

const AuctionWrap = styled.div`
  width: 100%;
  min-width: 1200px;
  height: 100%;
  padding-left: 240px;
  background-color: ${colors.emptyBackgroundColor};
  padding-bottom: 20px;
`;
const AuctionContent = styled.div`
  margin: 30px;
  padding: 30px;
  background-color: ${colors.whiteColor};
`;
const TableTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TableTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkestBlack};
`;
const TableHeader = styled.div`
  display: flex;
  margin-top: 20px;
`;
const SmsButtonWrap = styled.div`
  display: inline-block;
  margin-right: 4px;
`;
const Button = styled.button`
  width: 62px;
  margin-right: 12px;
  height: 30px;
  color: ${colors.whiteColor};
  border: none;
  border-radius: 4px;
  background-color: ${colors.activeBlue};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
const Box = styled.div``;

function AuctionPresenter({
  current,
  totalClick,
  auctionClick,
  failClick,
  doneClick,
  onSmsCheck,
  smsCheck,
  onFeesCheck,
  feesCheck,
  onTaxBillCheck,
  taxBillCheck,
  state,
  detailView,
  detailViewClick,
  modal,
  modalOpen,
  modalClose,
  data,
  onClickSmsCheck = () => null,
  sendMessage = () => null,
  messageModalOpen,
  smsList,
  messageInput,
  messageModal,
  messageModalClose,
  postCountData,
  excelData = { excelData: "없음" },
  onClickFeesCheck,
  onClickTaxBillCheck,
  selectSalesPost,
  setSelectSalesPost,
  handleObservation = () => null,
  excelLoading = false,
  deleteCheck = false,
  setDeleteCheck = () => null,
  onClickDeleteCheck = () => null,
  handleDeleteSalesPost = () => null,
}) {
  const { total, auction, fail, done } = current;
  return (
    <AuctionWrap>
      <ContentHead
        title="경매관리"
        subTitle="매물"
        bgColor={colors.activeBlue}
        subText="결재"
        auctionVisible={true}
        totalUsers="31"
        excelData={excelData}
        excelLoading={excelLoading}
      />
      <ContentTop
        menuNumber="12"
        title1="총 매물수"
        title2="거래중"
        title3="유찰수"
        title4="거래완료"
        activeTitleColor={true}
        current={current}
        totalClick={totalClick}
        auctionClick={auctionClick}
        failClick={failClick}
        doneClick={doneClick}
        postCountData={postCountData ? postCountData : 0}
      />
      <AuctionContent>
        <TableTitleBox>
          <TableTitle>매물 수</TableTitle>
          <Box>
            {/*<SmsButtonWrap>*/}
            {/*  <SmsButton messageModalOpen={messageModalOpen} />*/}
            {/*</SmsButtonWrap>*/}
            {total || auction || fail || done ? (
              <SmsButtonWrap>
                <SmsButton
                  title="삭제하기"
                  color="red"
                  messageModalOpen={handleDeleteSalesPost}
                />
              </SmsButtonWrap>
            ) : null}
          </Box>
        </TableTitleBox>
        <TableHeader>
          {total || auction || fail ? (
            <ContentsHeader
              headerTitle={[
                "경매관리번호",
                "경매일자",
                "딜러",
                "입찰자",
                "상태",
                "매입금액",
                // "명의이전",
                // "수수료",
                // "세금계산서",
                // "문자",
                "삭제",
              ]}
            />
          ) : null}
          {done && (
            <ContentsHeader
              headerTitle={[
                "경매관리번호",
                "경매일자",
                "딜러",
                "입찰자",
                "상태",
                "매입금액",
                // "명의이전",
                // "수수료",
                // "세금계산서",
                // "문자",
                "삭제",
              ]}
            />
          )}
        </TableHeader>

        {total || auction || fail ? (
          <>
            {data &&
            data.seeFullSalesPost &&
            data.seeFullSalesPost.salesPosts.length !== 0 ? (
              data.seeFullSalesPost.salesPosts.map((props, index) => (
                <AuctionTotal
                  smsCheck={smsCheck}
                  deleteCheck={deleteCheck}
                  setDeleteCheck={setDeleteCheck}
                  modal={modal}
                  modalOpen={modalOpen}
                  modalClose={modalClose}
                  data={props}
                  contentNumber={index + 1}
                  onClickSmsCheck={onClickSmsCheck}
                  key={"AuctionTable" + index}
                  setSelectSalesPost={setSelectSalesPost}
                  onClickDeleteCheck={onClickDeleteCheck}
                />
              ))
            ) : (
              <>
                {data &&
                  data.seeFullSalesPost &&
                  data.seeFullSalesPost.salesPosts.length === 0 &&
                  null}
                {data &&
                  data.seeFullSalesPost &&
                  data.seeFullSalesPost.salesPosts.length !== 0 && <Loader />}
              </>
            )}
          </>
        ) : null}
        {done && (
          <>
            {data &&
            data.seeFullSalesPost &&
            data.seeFullSalesPost.salesPosts.length !== 0 ? (
              data.seeFullSalesPost.salesPosts.map((props, index) => (
                <AuctionDone
                  smsCheck={smsCheck}
                  feesCheck={feesCheck}
                  deleteCheck={deleteCheck}
                  taxBillCheck={taxBillCheck}
                  setDeleteCheck={setDeleteCheck}
                  modal={modal}
                  modalOpen={modalOpen}
                  modalClose={modalClose}
                  onClickSmsCheck={onClickSmsCheck}
                  data={props}
                  key={"AuctionDoneTabe" + index}
                  onClickFeesCheck={onClickFeesCheck}
                  onClickTaxBillCheck={onClickTaxBillCheck}
                  setSelectSalesPost={setSelectSalesPost}
                  onClickDeleteCheck={onClickDeleteCheck}
                />
              ))
            ) : (
              <Loader />
            )}
          </>
        )}
      </AuctionContent>

      {/* Modal */}
      {selectSalesPost && (
        <BiddingDetailModal
          state={state}
          detailView={detailView}
          detailViewClick={detailViewClick}
          modal={modal}
          modalClose={modalClose}
          selectSalesPost={selectSalesPost}
          handleObservation={handleObservation}
        />
      )}
      <UserMessageModal
        messageModal={messageModal}
        messageModalClose={messageModalClose}
        smsList={smsList}
        messageInput={messageInput}
        sendMessage={sendMessage}
      />
    </AuctionWrap>
  );
}

export default AuctionPresenter;
