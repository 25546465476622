import React from "react";
import colors from "../../../styles/colors";
import styled from "styled-components";
import BarGraph from "../BarGraph";
import CountUp from "react-countup";
import { FiUsers } from "react-icons/fi";
import { RiAuctionLine, RiCheckboxCircleLine } from "react-icons/ri";
import { FaRegHandshake } from "react-icons/fa";

const TopWrap = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 30px 30px 30px;
`;
const TopMenu = styled.div`
  width: 25%;
  height: 114px;
  color: ${colors.darkerGray};
  padding: 10px;
  background: ${(props) =>
    props.state ? `${colors.activeBlue}` : `${colors.whiteColor}`};
  ${(props) =>
    props.borderRight &&
    `border-right: 1px solid ${colors.emptyBackgroundColor};`}
  ${(props) => (props.state ? `cursor: pointer;` : `cursor: pointer;`)}
`;
const MenuInfoBox = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const MenuInfo = styled.div`
  display: inline-block;
  margin-bottom: 17px;
`;
const MenuIcon = styled.div`
  font-size: 24px;
  margin-bottom: 5px;
  height: 24px;
  color: ${colors.darkestGray};
`;
const MenuText = styled.span`
  font-size: 18px;
  color: ${(props) => (props.color ? props.color : `${colors.darkestGray}`)};
  ${(props) => props.state && `color: ${colors.whiteColor};`}
`;
const MenuNumber = styled.span`
  font-size: 44px;
  color: ${(props) => (props.color ? props.color : `${colors.darkestBlack}`)};
  ${(props) => props.state && `color: ${colors.whiteColor};`}
`;

function ContentTop({
  menuNumber,
  title1,
  title2,
  title3,
  title4,
  iconVisible = false,
  barGraphVisible1 = false,
  barGraphVisible2 = false,
  barGraphVisible3 = false,
  barGraphVisible4 = false,
  barGraphColor1,
  barGraphColor2,
  barGraphColor3,
  barGraphColor4,
  menuNumberColor1,
  menuNumberColor2,
  menuNumberColor3,
  menuNumberColor4,
  current = {
    total: false,
    auction: false,
    fail: false,
    done: false,
  },
  totalClick,
  auctionClick,
  failClick,
  doneClick,
  postCountData,
}) {
  const { total, auction, fail, done } = current;
  return (
    <TopWrap>
      <TopMenu borderRight onClick={totalClick} state={total}>
        <MenuInfoBox>
          <MenuInfo>
            <MenuIcon>{iconVisible && <FiUsers />}</MenuIcon>
            <MenuText color={menuNumberColor1} state={total}>
              {title1}
            </MenuText>
          </MenuInfo>
          <MenuNumber color={menuNumberColor1} state={total}>
            <CountUp
              end={
                postCountData && postCountData.adminAllSalesPostCount
                  ? postCountData.adminAllSalesPostCount.allSalesPostCount
                  : 0
              }
            />
          </MenuNumber>
        </MenuInfoBox>
        {barGraphVisible1 && <BarGraph percent={80} color={barGraphColor1} />}
      </TopMenu>
      <TopMenu borderRight onClick={auctionClick} state={auction}>
        <MenuInfoBox>
          <MenuInfo>
            <MenuIcon>{iconVisible && <RiAuctionLine />}</MenuIcon>
            <MenuText state={auction}>{title2}</MenuText>
          </MenuInfo>
          <MenuNumber color={menuNumberColor2} state={auction}>
            <CountUp
              end={
                postCountData && postCountData.adminAllSalesPostCount
                  ? postCountData.adminAllSalesPostCount.tradingCount
                  : 0
              }
            />
          </MenuNumber>
        </MenuInfoBox>
        {barGraphVisible2 && <BarGraph percent={80} color={barGraphColor2} />}
      </TopMenu>
      <TopMenu borderRight onClick={failClick} state={fail}>
        <MenuInfoBox>
          <MenuInfo>
            <MenuIcon>{iconVisible && <FaRegHandshake />}</MenuIcon>
            <MenuText state={fail}>{title3}</MenuText>
          </MenuInfo>
          <MenuNumber color={menuNumberColor3} state={fail}>
            <CountUp
              end={
                postCountData && postCountData.adminAllSalesPostCount
                  ? postCountData.adminAllSalesPostCount.observationCount
                  : 0
              }
            />
          </MenuNumber>
        </MenuInfoBox>
        {barGraphVisible3 && <BarGraph percent={80} color={barGraphColor3} />}
      </TopMenu>
      <TopMenu onClick={doneClick} state={done}>
        <MenuInfoBox>
          <MenuInfo>
            <MenuIcon>{iconVisible && <RiCheckboxCircleLine />}</MenuIcon>
            <MenuText color={menuNumberColor4} state={done}>
              {title4}
            </MenuText>
          </MenuInfo>
          <MenuNumber color={menuNumberColor4} state={done}>
            <CountUp
              end={
                postCountData && postCountData.adminAllSalesPostCount
                  ? postCountData.adminAllSalesPostCount.completeCount
                  : 0
              }
            />
          </MenuNumber>
        </MenuInfoBox>
        {barGraphVisible4 && <BarGraph percent={80} color={barGraphColor4} />}
      </TopMenu>
    </TopWrap>
  );
}

export default ContentTop;
