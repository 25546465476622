import { gql } from "@apollo/client";

export const SEE_NOTICE_INFO = gql`
  query seeNoticeInfo($noticeId: Int!) {
    seeNoticeInfo(noticeId: $noticeId) {
      id
      createdAt
      updatedAt
      adminPhoneNumber
      account
    }
  }
`;

export const SEE_NOTICE_FILE = gql`
  query seeNoticeFile {
    seeNoticeFile {
      id
      createdAt
      updatedAt
      url
    }
  }
`;
