import React, { useState } from "react";
import colors from "../../../styles/colors";
import styled from "styled-components";
import { ADMIN_COMMAND_PM2 } from "../../../graphql/user/mutation";
import { useMutation } from "@apollo/client";
// import BarGraph from "../BarGraph";
// import CountUp from "react-countup";
// import { RiAuctionLine, RiCheckboxCircleLine } from "react-icons/ri";

const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 30px 30px 30px;
`;

const ButtonDiv = styled.div`
  width: 100%;
  //height: 114px;
  color: ${colors.darkerGray};
  padding: 10px;
  background: ${(props) =>
    props.state ? `${colors.activeBlue}` : `${colors.whiteColor}`};
  ${(props) =>
    props.borderRight &&
    `border-right: 1px solid ${colors.emptyBackgroundColor};`}
`;
const Button = styled.button`
  margin-left: 12px;
  width: 120px;
  height: 50px;
  font-size: 16px;
  line-height: 16px;
  border: none;
  border-radius: 4px;
  text-align: center;
  color: ${colors.whiteColor};
  float: right;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  ${(props) =>
    props.toggleSearchUser
      ? props.disabled
        ? `background-color: ${colors.grayColor};`
        : `background-color: ${colors.activePurple};`
      : `background-color: ${colors.activeBlue};`}
  outline: none;
  &:active {
    opacity: 0.8;
  }
  cursor: pointer;
`;

function PasswordChange({
  passModalOpen,
  // title4,
  // menuNumberColor4,
  // current = {
  //   total: false,
  //   auction: false,
  //   fail: false,
  //   done: false,
  // },
  // doneClick,
  // postCountData,
}) {
  // const { total, auction, fail, done } = current;
  // console.log("passModalOpen", passModalOpen);
  const [adminCommandPm2] = useMutation(ADMIN_COMMAND_PM2);
  const [pm2ButtonText, setPm2ButtonText] = useState("PM2 재시작");
  const [pm2ButtonDisabled, setPm2ButtonDisabled] = useState(false);

  const handlePm2Restart = async () => {
    await adminCommandPm2({
      variables: {
        command: "restart",
      },
    });

    let countdown = 30;
    setPm2ButtonText(countdown);
    setPm2ButtonDisabled(true);

    const interval = setInterval(() => {
      countdown--;

      if (countdown > 0) {
        setPm2ButtonText(countdown);
      } else {
        setPm2ButtonText("PM2 재시작");
        window.location.reload();
        clearInterval(interval);
      }
    }, 1000);
  };

  return (
    <ButtonWrap>
      <ButtonDiv>
        <Button
          toggleSearchUser={true}
          disabled={pm2ButtonDisabled}
          onClick={() => handlePm2Restart()}
        >
          {pm2ButtonText}
        </Button>
        <Button onClick={() => passModalOpen(true)}>비밀번호 변경</Button>
      </ButtonDiv>
    </ButtonWrap>
  );
}

export default PasswordChange;
