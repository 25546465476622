import { useMutation, useQuery } from "@apollo/client";
import React, { useState, useReducer, useCallback } from "react";
import { toast } from "react-toastify";
import {
  ADD_COMMISSION,
  ADD_TAX,
  DELETE_SALES_POST,
  FORCE_OBSERVATION,
  TRADE_COMPLETE,
} from "../../graphql/auction/mutation.js";
import {
  ADMIN_ALL_SALES_POST_COUNT,
  EXCEL_CONVERT,
  SEE_FULL_SALES_POST,
} from "../../graphql/auction/query.js";
import { SEND_MASS_SMS } from "../../graphql/user/mutation.js";
import useInput from "../../hooks/useInput.js";
import AuctionPresenter from "./AuctionPresenter.js";

// bidding state
function biddingReducer(state, action) {
  switch (action.type) {
    case "CANCEL":
      return {
        cancel: true,
        bidding: false,
        waiting: false,
      };
    case "BIDDING":
      return {
        cancel: false,
        bidding: true,
        waiting: false,
      };
    case "WAITING":
      return {
        cancel: false,
        bidding: false,
        waiting: true,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function AuctionContainer() {
  const [messageModal, setMessageModal] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [smsAllCheck, setSmsAllCheck] = useState(false);
  const [feesCheck, setFeesCheck] = useState(false);
  const [taxBillCheck, setTaxBillCheck] = useState(false);
  const [modal, setModal] = useState(false);
  const [detailView, setDetailView] = useState(false);
  const [smsList, setSmsList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const messageInput = useInput("");
  const [selectSalesPost, setSelectSalesPost] = useState({});

  const [state, dispatch] = useReducer(biddingReducer, {
    cancel: false,
    bidding: false,
    waiting: true,
  });
  const [current, setCurrent] = useState({
    total: true,
    auction: false,
    fail: false,
    done: false,
  });

  const postStatus = useCallback(() => {
    if (current.total) {
      return "all";
    }
    if (current.auction) {
      return "trading";
    }
    if (current.fail) {
      return "observation";
    }
    if (current.done) {
      return "complete";
    }
  });

  const { data, loading, error } = useQuery(SEE_FULL_SALES_POST, {
    variables: {
      status: postStatus(),
    },
    pollInterval: 5000,
  });

  const { data: postCountData, loading: postCountLoading } = useQuery(
    ADMIN_ALL_SALES_POST_COUNT,
    {
      pollInterval: 5000,
    }
  );

  // console.log(postCountData);
  const {
    data: excelData,
    loading: excelLoading,
    error: excelError,
  } = useQuery(EXCEL_CONVERT, {
    variables: {
      tableName: "SalesPost",
    },
  });

  const [forceObservionMutation] = useMutation(FORCE_OBSERVATION);
  const [sendMessageMutation] = useMutation(SEND_MASS_SMS);
  const [addTaxMutation] = useMutation(ADD_TAX);
  const [addCommissionMutation] = useMutation(ADD_COMMISSION);
  const [deleteSalesPostMutation] = useMutation(DELETE_SALES_POST);

  const handleSmsAllCheck = () => {
    setSmsAllCheck(true);
  };

  const handleDeleteSalesPost = async () => {
    if (deleteList.length === 0) {
      return toast("삭제 할 매물을 선택해주세요.");
    }
    try {
      const result = await deleteSalesPostMutation({
        variables: {
          salesPostId: deleteList,
        },
        refetchQueries: [
          {
            query: SEE_FULL_SALES_POST,
            variables: {
              status: postStatus(),
            },
          },
        ],
      });
      if (result) {
        toast.success("삭제가 완료되었습니다.");
        setDeleteList([]);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleObservation = async (salesPostId) => {
    try {
      const result = await forceObservionMutation({
        variables: {
          salesPostId,
        },
        // refetchQueries: [
        //   {
        //     query: SEE_FULL_SALES_POST,
        //     variables: {
        //       status: "trading",
        //     },
        //   },
        // ],
      });
      if (result) {
        toast("유찰하기로 변경되었습니다.");
      }
    } catch (e) {
      toast.error(e);
      console.log(e);
    }
  };


  const onClickSmsCheck = (state, phoneNumber) => {
    console.log("문자 박스 체크 실행");
    if (state) {
      setSmsList(smsList.filter((prevNumber) => phoneNumber !== prevNumber));
    }
    if (!state) {
      setSmsList([...smsList, phoneNumber]);
    }
  };

  const onClickDeleteCheck = (state, id) => {
    console.log("삭제 체크박스 실행");
    if (state) {
      return setDeleteList(deleteList.filter((prevId) => id !== prevId));
    }
    if (!state) {
      return setDeleteList([...deleteList, id]);
    }
  };
  console.log(deleteList);

  const onClickFeesCheck = async (salesPostId) => {
    console.log("수수료 박스 체크 실행", [salesPostId]);
    try {
      console.log("뮤테이션 실행");
      const result = await addCommissionMutation({
        variables: {
          salesPostId: [salesPostId],
        },
      });
      console.log("뮤테이션 결과", result);
      if (result) {
        toast("수수료 상태가 변경되었습니다");
      }
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };
  const onClickTaxBillCheck = async (salesPostId) => {
    console.log("수수료 박스 체크 실행", [salesPostId]);
    try {
      console.log("뮤테이션 실행");
      const result = await addTaxMutation({
        variables: {
          salesPostId: [salesPostId],
        },
      });
      console.log("뮤테이션 결과", result);
      if (result) {
        toast("세금계산서 상태가 변경되었습니다");
      }
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const sendMessage = async () => {
    if (messageInput.value === "") {
      return alert("메시지를 입력해주세요");
    }
    if (smsList.length === 0) {
      return window.alert("문자 보낼 번호를 선택하세요.");
    }
    try {
      const result = await sendMessageMutation({
        variables: {
          phoneNumbers: smsList,
          msg: messageInput.value,
        },
      });
      if (result) {
        return (
          window.alert("메시지가 전송되었습니다."),
          setMessageModal(!messageModal)
        );
      }
    } catch (e) {
      toast(e.message);
      console.log(e);
    }
  };

  if (error) {
    toast(error.message);
    console.log(error);
  }



  const messageModalOpen = () => {
    setMessageModal(true);
  };
  const messageModalClose = () => {
    setMessageModal(false);
  };

  // 매물 상세정보 상태(거래취소, 거래중, 대기중)

  const totalClick = () => {
    setSmsCheck(false);
    setCurrent({
      total: true,
      auction: false,
      fail: false,
      done: false,
    });
  };
  const auctionClick = () => {
    setSmsCheck(false);
    setCurrent({
      total: false,
      auction: true,
      fail: false,
      done: false,
    });
  };
  const failClick = () => {
    setSmsCheck(false);
    setCurrent({
      total: false,
      auction: false,
      fail: true,
      done: false,
    });
  };
  const doneClick = () => {
    setSmsCheck(false);
    setCurrent({
      total: false,
      auction: false,
      fail: false,
      done: true,
    });
  };

  // 매물 상세 정보 자세히보기
  const detailViewClick = () => {
    setDetailView(!detailView);
  };
  // 모달 열기
  const modalOpen = () => {
    setModal(true);
  };
  const modalClose = () => {
    setModal(false);
  };

  return (
    <>
      <AuctionPresenter
        current={current}
        totalClick={totalClick}
        auctionClick={auctionClick}
        failClick={failClick}
        doneClick={doneClick}
        smsCheck={smsCheck}
        deleteCheck={deleteCheck}
        setDeleteCheck={setDeleteCheck}
        feesCheck={feesCheck}
        taxBillCheck={taxBillCheck}
        state={state}
        detailView={detailView}
        detailViewClick={detailViewClick}
        modal={modal}
        modalOpen={modalOpen}
        modalClose={modalClose}
        data={data}
        onClickSmsCheck={onClickSmsCheck}
        sendMessage={sendMessage}
        messageModalOpen={messageModalOpen}
        messageModalClose={messageModalClose}
        smsList={smsList}
        messageInput={messageInput}
        messageModal={messageModal}
        postCountData={postCountData}
        excelData={excelData && excelData}
        onClickFeesCheck={onClickFeesCheck}
        onClickTaxBillCheck={onClickTaxBillCheck}
        selectSalesPost={selectSalesPost}
        setSelectSalesPost={setSelectSalesPost}
        handleObservation={handleObservation}
        excelLoading={excelLoading}
        smsAllCheck={smsAllCheck}
        handleSmsAllCheck={handleSmsAllCheck}
        onClickDeleteCheck={onClickDeleteCheck}
        handleDeleteSalesPost={handleDeleteSalesPost}
      />
    </>
  );
}

export default AuctionContainer;
