import React, {useState} from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import {IoStar, IoStarOutline} from "react-icons/io5";
import {EDIT_BIDDING} from "../../../graphql/auction/mutation";
import {useMutation} from "@apollo/client";
import {SEE_FULL_SALES_POST} from "../../../graphql/auction/query";

const BodyWrap = styled.div`
  width: 100%;
  display: flex;
  padding: 0 20px;
`;
const DevideView = styled.div`
  width: calc(100% / 10);
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colors.borderColor};
  padding: 12px 0;
`;
const RadioButton = styled.input`
  text-align: center;
  padding: 4px;
`;
const TebleView = styled.p`
  flex: 1;
`;
const TebleText = styled.span`
  color: ${(props) => props.color};
  text-align: center;
  padding: 4px;
`;
const FeedbackButton = styled.button`
  border: 0;
  background-color: black;
  color: #fff;
  margin-top: 10px;
  padding: 5px;
`;
const FeedbackReasonTextArea = styled.textarea`
  width: 120px;
  flex: 1;
  padding: 4px;
`;

function BiddingStatus({
                           id,
                           price = "",
                           status = "register",
                           discountReason = "",
                           buyer = {},
                           condition,
                           sellerCancellationReasons = undefined,
                           buyerCancellationReasons = undefined,
                           createdAt = "20200301",
                           state = "",
                           handleSelectBidding = () => null,
                           selectBidding,
                           feedbackScore = 0,
                           feedbackReason = ''
                       }) {
    const [isEditingMode, setIsEditingMode] = useState(false);
    const [newFeedbackScore, setNewFeedbackScore] = useState(feedbackScore);
    const [newFeedbackReason, setNewFeedbackReason] = useState(feedbackReason);

    const [editBidding] = useMutation(EDIT_BIDDING);

    console.log("discountReason =>", discountReason, status);
    console.log("bidding =>", selectBidding);
    const createDate = new Date(parseInt(createdAt))
        .toLocaleDateString()
        .split(".");

    const createHours = new Date(parseInt(createdAt)).getHours();
    const createMin = new Date(parseInt(createdAt)).getMinutes();
    // console.log(createHours, createMin);
    console.log("handleBidding", selectBidding, id);
    console.log("checked", selectBidding === id);

    const onSubmitFeedback = () => {
        console.log(">>>>>>>>>>>>>>>>>> onSubmitFeedback", { newFeedbackScore, newFeedbackReason });
        (async () => {
            try {
                const result = await editBidding({
                    variables: {
                        biddingId: id,
                        feedbackScore: newFeedbackScore,
                        feedbackReason: newFeedbackReason
                    }
                });
                console.log(result)
            } catch (e) {
                console.log(e);
            }
        })();
    };
    return (
        <BodyWrap>
            <DevideView>
                <RadioButton
                    type="radio"
                    onClick={() => handleSelectBidding(id)}
                    checked={selectBidding === id}
                    value={id}
                />
            </DevideView>
            <DevideView>
                <TebleText>{buyer && buyer.name}</TebleText>
            </DevideView>
            <DevideView>
                <TebleText>{buyer && buyer.phoneNumber}</TebleText>
            </DevideView>
            <DevideView>
                <TebleText>
                    {price !== ""
                        ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "만원"
                        : "-"}
                </TebleText>
            </DevideView>
            <DevideView>
                <TebleText>
                    {status === "cancel" && "거래취소"}
                    {status === "trading" && "거래중"}
                    {status === "register" && "대기중"}
                    {status === "complete" && "거래완료"}
                </TebleText>
            </DevideView>
            <DevideView>
                <TebleText>
                    {sellerCancellationReasons &&
                        sellerCancellationReasons.length !== 0 &&
                        sellerCancellationReasons[0].contactFail &&
                        "연락두절"}
                    {sellerCancellationReasons &&
                        sellerCancellationReasons.length !== 0 &&
                        sellerCancellationReasons[0].excessiveDiscount &&
                        "과도한감가"}
                    {sellerCancellationReasons &&
                        sellerCancellationReasons.length !== 0 &&
                        sellerCancellationReasons[0].estimateMistake &&
                        "견적실수"}
                    {sellerCancellationReasons &&
                        sellerCancellationReasons.length !== 0 &&
                        sellerCancellationReasons[0].differentInformation &&
                        "정보와다름"}
                    {sellerCancellationReasons &&
                        sellerCancellationReasons.length !== 0 &&
                        sellerCancellationReasons[0].buyerCancelRequest &&
                        "구매자취소요청"}
                    {sellerCancellationReasons &&
                        sellerCancellationReasons.length !== 0 &&
                        sellerCancellationReasons[0].other &&
                        "기타"}
                    {buyerCancellationReasons &&
                        buyerCancellationReasons.length !== 0 &&
                        buyerCancellationReasons[0].accidentDifferent &&
                        "사고차량"}
                    {buyerCancellationReasons &&
                        buyerCancellationReasons.length !== 0 &&
                        buyerCancellationReasons[0].gradeDifferent &&
                        "등급 다름"}
                    {buyerCancellationReasons &&
                        buyerCancellationReasons.length !== 0 &&
                        buyerCancellationReasons[0].estimateMistake &&
                        "견적실수"}
                    {buyerCancellationReasons &&
                        buyerCancellationReasons.length !== 0 &&
                        buyerCancellationReasons[0].discountDepreciation &&
                        "감가 거절"}
                    {buyerCancellationReasons &&
                        buyerCancellationReasons.length !== 0 &&
                        buyerCancellationReasons[0].scheduleDifferent &&
                        "연락두절"}
                    {buyerCancellationReasons &&
                        buyerCancellationReasons.length !== 0 &&
                        buyerCancellationReasons[0].carStatusBad &&
                        "상태 나쁨"}
                    {buyerCancellationReasons &&
                        buyerCancellationReasons.ohter &&
                        buyerCancellationReasons[0].length !== 0 &&
                        "기타"}
                </TebleText>
                {/* <TebleText>{cancelReason ? cancelReason : "-"}</TebleText> */}
            </DevideView>
            <DevideView>
                <TebleText>{condition}</TebleText>
            </DevideView>
            <DevideView>
                <TebleText>{status === "complete" ? discountReason : ""}</TebleText>
            </DevideView>
            <DevideView>
                <TebleText>
                    {createDate[0]}. {createDate[1]}. {createDate[2]} <br />
                    {createHours}시 {createMin}분
                </TebleText>
            </DevideView>
            {
                status === "complete" ? (
                    isEditingMode ? (
                        <DevideView style={{ flexDirection: 'column' }}>
                            <TebleView>
                                {Array.from({ length: 5 }, (x, i) => i)
                                    .map((e, i) => {
                                        if (newFeedbackScore > i) return <IoStar key={`star${i}`} onClick={() => setNewFeedbackScore(i+1)} />
                                        else return <IoStarOutline key={`star${i}`} onClick={() => setNewFeedbackScore(i+1)} />
                                    })}
                            </TebleView>
                            <TebleView>
                                <FeedbackReasonTextArea row={4} placeholder={newFeedbackReason} onChange={(e) => setNewFeedbackReason(e.target.value)}>
                                    {newFeedbackReason}
                                </FeedbackReasonTextArea>
                            </TebleView>
                            <FeedbackButton onClick={() => {
                                onSubmitFeedback();
                                setIsEditingMode(false);
                            }}>저장</FeedbackButton>
                        </DevideView>
                    ) : (
                        <DevideView style={{ flexDirection: 'column' }}>
                            <TebleView>
                                {(
                                    Array.from({ length: 5 }, (x, i) => i)
                                        .map((e, i) => {
                                            if (newFeedbackScore > i) return <IoStar />
                                            else return <IoStarOutline />
                                        })
                                )}
                            </TebleView>
                            <TebleView>
                                <TebleText>{newFeedbackReason}</TebleText>
                            </TebleView>
                            <FeedbackButton onClick={() => setIsEditingMode(true)}>수정</FeedbackButton>
                        </DevideView>
                    )
                ) : null
            }
        </BodyWrap>
    );
}

export default React.memo(BiddingStatus);
