import React from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";
import colors from "../../styles/colors";

const View = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 530px;
`;

export default () => {
  return (
    <View>
      <ReactLoading loading={true} color={colors.darkerGray} />
    </View>
  );
};
