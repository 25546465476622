export default {
  //Color

  // General
  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  grayColor: "#999",
  lightGrayColor: "#EDEDED",

  //Point
  activeBlue: "#1EBFC7",
  activeRed: "#F8674D",
  activeGreen: "#6EBF86",
  activePurple: "#A587DF",
  inactiveColor: "#96A1AB",

  //Font
  darkestBlack: "#3C444F",
  darkestGray: "#535965",
  darkerGray: "#96A1AB",

  // Background
  shadowColor: "rgb(216, 216, 216)",
  emptyBackgroundColor: "#EDF0F5",

  // Border
  borderColor: "#C7CED5",
  darkBorderColor: "#707070",

  // Today Color
  grayishColor: "#AAB2BB",

  // SideMenuColor
  skyBlue: "#00B1D2",

  // HomeComponent
  lightenGray: "#F6FAFD",
  lightOrange: "#F9957B",


};
