import React from "react";
import colors from "../../../styles/colors";
import styled from "styled-components";
import TableBodyData from "./TableBodyData";
import CountUp from "react-countup";

const TotalAmount = styled.div`
  width: 100%;
  min-height: 441px;
  padding: 20px 0;
  background-color: ${colors.whiteColor};
`;
const Title = styled.h1`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkestBlack};
  padding: 0 24px;
`;
const DateAmount = styled.div`
  width: 100%;
  height: 95px;
  margin: 20px 0;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.lightenGray};
  font-size: 20px;
  color: ${colors.grayishColor};
`;
const Money = styled.div`
  font-size: 30px;
  color: ${colors.activeBlue};
`;
const TableWrap = styled.div`
  padding: 10px 24px;
`;
const TableHeaderLine = styled.div`
  padding: 12px 0;
  font-size: 16px;
  text-align: center;
  color: ${colors.darkerGray};
  border-bottom: 1px solid ${colors.borderColor};
`;
const TableHeader = styled.div`
  width: 20%;
  display: inline-block;
`;

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numberToKorean(number) {
  var inputNumber = number < 0 ? false : number;
  var unitWords = ["", "만", "억", "조", "경"];
  var splitUnit = 10000;
  var splitCount = unitWords.length;
  var resultArray = [];
  var resultString = "";

  for (var i = 0; i < splitCount; i++) {
    var unitResult =
      (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (var i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    resultString =
      String(numberFormat(resultArray[i])) + unitWords[i] + resultString;
  }

  return resultString;
}

function ContentTable({ dateString, count, money = 0, contents = [] }) {
  return (
    <TotalAmount>
      <Title>금일 거래금액 총량 ({count}회) </Title>
      <DateAmount>
        {dateString}
        <Money>
          {/* {numberToKorean(money)}만원 */}
          {money === 0 && "0 원"}
          {money !== 0 && (
            <>
              <CountUp end={money} /> 만원
            </>
          )}
        </Money>
      </DateAmount>
      <TableWrap>
        <TableHeaderLine>
          <TableHeader>관리번호</TableHeader>
          <TableHeader>내용</TableHeader>
          <TableHeader>금액</TableHeader>
          <TableHeader>판매자</TableHeader>
          <TableHeader>구매자</TableHeader>
        </TableHeaderLine>
        {contents.length !== 0 &&
          contents.map((props) => (
            <TableBodyData {...props} key={"TodayDealAmount" + props.id} />
          ))}
      </TableWrap>
    </TotalAmount>
  );
}

export default ContentTable;
