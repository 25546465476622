import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import ModalButton from "../../share/Modal/ModalButton";

const BuyerDetailWrap = styled.div`
  margin-top: 50px;
`;
const FailedReasonText = styled.h5`
  color: ${colors.darkestBlack};
  margin: 11px 0;
`;
const FailedReasonBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const TableBox = styled.div``;
const TableLine = styled.div`
  width: 470px;
  display: flex;
  justify-content: space-between;
  padding: 15px 5px 15px 0;
  border-top: 1px solid ${colors.borderColor};
`;
const ReasonBox = styled.div`
  width: 72px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;
const ReasonText = styled.div`
  color: ${colors.darkestGray};
  vertical-align: text-top;
  margin-bottom: 4px;
  text-align: center;
`;
const ReasonCount = styled.div`
  margin-left: 3px;
  font-size: 22px;
  color: ${colors.darkestGray};
`;
const ResetButtonBox = styled.div``;
const CancelCount = styled.div`
  width: 145px;
  margin-bottom: 11px;
  text-align: center;
  color: ${colors.lightOrange};
  font-weight: bold;
`;

function BuyerDetail({
  depreciation,
  abuse,
  calumny,
  lostContact,
  cancleCount,
  stopedCount,
  data = [],
  handelCancelReset,
}) {
  const {
    id,
    accidentDifferent,
    gradeDifferent,
    estimateMistake,
    discountDepreciation,
    scheduleDifferent,
    carStatusBad,
    ohter,
  } = data;
  return (
    <BuyerDetailWrap>
      <FailedReasonText>거래 취소 사유</FailedReasonText>
      <FailedReasonBox>
        <TableBox>
          <TableLine>
            <ReasonBox>
              <ReasonText>사고차량 </ReasonText>
              <ReasonCount>{accidentDifferent}</ReasonCount>
            </ReasonBox>
            <ReasonBox>
              <ReasonText>다른등급 </ReasonText>
              <ReasonCount>{gradeDifferent}</ReasonCount>
            </ReasonBox>
            <ReasonBox>
              <ReasonText>{"견적실수 "}</ReasonText>
              <ReasonCount>{estimateMistake}</ReasonCount>
            </ReasonBox>
            <ReasonBox>
              <ReasonText>{"감가 요구 "}</ReasonText>
              <ReasonCount>{discountDepreciation}</ReasonCount>
            </ReasonBox>
            <ReasonBox>
              <ReasonText>{"연락두절 "}</ReasonText>
              <ReasonCount>{scheduleDifferent}</ReasonCount>
            </ReasonBox>
          </TableLine>
          <TableLine>
            <ReasonBox>
              <ReasonText>{"차량상태 불량 "}</ReasonText>
              <ReasonCount>{carStatusBad}</ReasonCount>
            </ReasonBox>
            <ReasonBox>
              <ReasonText>{"기타사유 "}</ReasonText>
              <ReasonCount>{ohter}</ReasonCount>
            </ReasonBox>
            <ReasonBox>
              <ReasonText></ReasonText>
              <ReasonCount></ReasonCount>
            </ReasonBox>
            <ReasonBox>
              <ReasonText></ReasonText>
              <ReasonCount></ReasonCount>
            </ReasonBox>
            <ReasonBox>
              <ReasonText></ReasonText>
              <ReasonCount></ReasonCount>
            </ReasonBox>
          </TableLine>
        </TableBox>
        <ResetButtonBox>
          {/* <CancelCount>정지 횟수 : {stopedCount}</CancelCount> */}
          <ModalButton
            color={colors.whiteColor}
            bgColor={colors.lightOrange}
            btnText=" 취소 내역 초기화"
            onClick={handelCancelReset}
          ></ModalButton>
        </ResetButtonBox>
      </FailedReasonBox>
    </BuyerDetailWrap>
  );
}

BuyerDetail.defaultProps = {
  depreciation: "1",
  abuse: "1",
  calumny: "1",
  lostContact: "1",
  cancleCount: "3",
};

export default BuyerDetail;
