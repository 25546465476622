import React from 'react';
import styled from 'styled-components';
import TableBodyData from './TableBodyData';

const AuctionIngBox = styled.div``;

function AuctionIng({ onSmsCheck, smsCheck }) {
  return (
    <AuctionIngBox>
      <TableBodyData onSmsCheck={onSmsCheck} smsCheck={smsCheck} />
      경매중
    </AuctionIngBox>
  );
}

export default AuctionIng;
