import React from "react";
import styled from "styled-components";

const Button = styled.button`
  width: 145px;
  height: 40px;
  border-radius: 12px;
  text-align: center;
  color: ${(props) => props.color};
  font-weight: bold;
  border: 1px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.bgColor};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  & + & {
    margin-top: 10px;
  }
`;

function ModalButton({ color, bgColor, btnText, borderColor, onClick }) {
  return (
    <Button
      color={color}
      bgColor={bgColor}
      borderColor={borderColor}
      onClick={onClick}
    >
      {btnText}
    </Button>
  );
}

export default ModalButton;
