import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import { IoMdSquareOutline, IoMdSquare } from "react-icons/io";

const TableWrap = styled.div`
  width: 100%;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.borderColor};
`;
const DevideView = styled.div`
  width: calc(100% / 8);
  display: flex;
  justify-content: center;
`;
const UserMessageView = styled.span`
  cursor: pointer;
  color: ${colors.darkestBlack};
  font-weight: bold;
  &:hover {
    color: ${colors.activeBlue};
  }
`;
const UserStopView = styled.span`
  cursor: pointer;
  color: ${colors.darkestBlack};
  font-weight: bold;
  &:hover {
    color: ${colors.lightOrange};
  }
`;
const EmployeeLink = styled.span`
  cursor: pointer;
  color: ${colors.darkestBlack};
  font-weight: bold;
  &:hover {
    color: ${colors.activeBlue};
  }
`;
const ContentText = styled.span`
  color: ${colors.darkestBlack};
`;

const SMSView = styled.button`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
  outline: null;
  &:focus {
    outline-width: 0;
  }
`;

function TableBodyData({
  userInfoModalOpen,
  stopUserModalOpen,
  id = 0,
  createdAt,
  updatedAt,
  name,
  phoneNumber = "01012341234",
  company,
  role,
  authImage,
  stoped,
  stopedCount,
  adminConfirm,
  onClickSmsCheck,
  handleStopUser = () => null,
  userData,
  handleConfirmUser = () => null,
  smsAllCheck = false,
  messageModal,
}) {
  const [smsChecked, setSmsCheck] = useState(false);
  const createDate = new Date(parseInt(createdAt))
    .toLocaleDateString()
    .split(".");

  useEffect(() => {
    setSmsCheck(false);
  }, [messageModal]);
  console.log("userInfoModalOpen", userInfoModalOpen);
  return (
    <TableWrap>
      <DevideView>
        {role === "buyer" && <ContentText>수출사업자</ContentText>}
        {role === "seller" && <ContentText>딜러</ContentText>}
      </DevideView>
      <DevideView>
        <ContentText>
          {createDate[0]}. {createDate[1]}. {createDate[2]}
        </ContentText>
      </DevideView>
      <DevideView onClick={() => userInfoModalOpen(userData)}>
        <UserMessageView>{name}</UserMessageView>
      </DevideView>
      <DevideView>
        <ContentText>
          {phoneNumber
            .replace(/[^0-9]/g, "")
            .replace(
              /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
              "$1-$2-$3"
            )
            .replace("--", "-")}
        </ContentText>
      </DevideView>
      <DevideView>
        {authImage && authImage !== null ? (
          <a href={authImage} target="_blank">
            <EmployeeLink>{authImage.substr(0, 7)}...</EmployeeLink>
          </a>
        ) : (
          <EmployeeLink>-</EmployeeLink>
        )}
      </DevideView>
      <DevideView onClick={() => handleStopUser(id, stoped)}>
        {!stoped ? (
          <UserStopView>정상</UserStopView>
        ) : (
          <UserStopView>정지중</UserStopView>
        )}
      </DevideView>
      <DevideView>
        <ContentText>{stopedCount ? `${stopedCount}` : 0}</ContentText>
      </DevideView>
      <DevideView onClick={() => handleConfirmUser(id, adminConfirm)}>
        <ContentText>
          {!adminConfirm ? (
            <UserStopView>미인증</UserStopView>
          ) : (
            <UserStopView>인증</UserStopView>
          )}
        </ContentText>
      </DevideView>
    </TableWrap>
  );
}

export default React.memo(TableBodyData);
