import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  CHANGE_ADMIN_ACCOUNT,
  NOTICE_FILE_CREATE,
  NOTICE_FILE_DELETE,
} from "../../graphql/notice/mutation";
import { SEE_NOTICE_FILE, SEE_NOTICE_INFO } from "../../graphql/notice/query";
import useInput from "../../hooks/useInput";
import NotificationPresenter from "./NotificationPresenter";
import axios from "axios";
import ServerIP from "../../apollo/ServerIP";

function NotificationContainer() {
  const [loading, setLoading] = useState(false);
  const accountInput = useInput("");
  const phoneNumberInput = useInput("");
  const PhoneRegex = /^[0-9]{11}$/;
  const [fileChange, setFileChange] = useState("파일 가져오기");

  const [changeAdminAccountMutation] = useMutation(CHANGE_ADMIN_ACCOUNT);
  const [noticeFileDeleteMutation] = useMutation(NOTICE_FILE_DELETE);
  const [noticeUploadMutation] = useMutation(NOTICE_FILE_CREATE);

  const {
    loading: noticeLoading,
    data: noticeData,
    error: noticeError,
  } = useQuery(SEE_NOTICE_INFO, {
    variables: {
      noticeId: 1,
    },
  });

  const { loading: fileLoading, data: fileData, error: fileError } = useQuery(
    SEE_NOTICE_FILE
  );

  const handleRemoveFile = async (noticeId) => {
    const result = await window.confirm("정말 삭제하시겠어요?");
    if (result) {
      try {
        const r = noticeFileDeleteMutation({
          variables: {
            noticeId: noticeId,
          },
          refetchQueries: [
            {
              query: SEE_NOTICE_FILE,
            },
          ],
        });
        if (r) {
          toast("삭제가 완료되었습니다.");
        }
      } catch (e) {
        toast.error(e.message);
        console.log(e);
      }
    }
  };

  const handleChangeAccount = async () => {
    console.log("계좌변경 실행");
    if (accountInput.value === "") {
      return toast.error("변경할 계좌번호를 입력하세요.");
    }
    setLoading(true);
    try {
      const result = await changeAdminAccountMutation({
        variables: {
          noticeId: 1,
          account: accountInput.value,
        },
        refetchQueries: [
          {
            query: SEE_NOTICE_INFO,
            variables: {
              noticeId: 1,
            },
          },
        ],
      });
      if (result) {
        toast("계좌가 성공적으로 변경되었습니다");
        accountInput.setValue("");
      }
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  const handleChangePhoneNumber = async () => {
    console.log("휴대전화번호 변경 실행");
    if (phoneNumberInput.value === "") {
      return toast.error("변경할 휴대전화번호를 입력하세요.");
    }
    if (!PhoneRegex.test(phoneNumberInput.value)) {
      return toast.error("올바른 전화번호를 입력해주세요");
    }
    setLoading(true);
    try {
      const result = await changeAdminAccountMutation({
        variables: {
          noticeId: 1,
          adminPhoneNumber: phoneNumberInput.value,
        },
        refetchQueries: [
          {
            query: SEE_NOTICE_INFO,
            variables: {
              noticeId: 1,
            },
          },
        ],
      });
      if (result) {
        toast("휴대폰 번호가 성공적으로 변경되었습니다");
        phoneNumberInput.setValue("");
      }
    } catch (e) {
      toast.error(e.message);
      console.log(e);
    }
  };

  // const fileUpload = (_file) => {
  //   const url = +"http://61.83.147.71:9755/graphql/api/adminImageUpload";
  //   const formData = new FormData();
  //   formData.append("file", _file);
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //     },
  //   };
  //   return axios.post(url, formData, config);
  // };

  const handleUpload = async (fileChange) => {
    console.log("업로드 실행");
    // const url = "http://61.83.147.71:9755/graphql/api/adminImageUpload";
    // const url = "https://api.crigging.shop/api/adminImageUpload";
    const url = "https://api.bluecompany.kr/api/adminImageUpload";
    console.log("url", url);
    const formData = new FormData();
    formData.append("file", fileChange);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.post(url, formData, config);
      if (response) {
        console.log("response", response);
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };

  return (
    <NotificationPresenter
      loading={loading}
      accountInput={accountInput}
      handleChangeAccount={handleChangeAccount}
      handleChangePhoneNumber={handleChangePhoneNumber}
      phoneNumberInput={phoneNumberInput}
      noticeData={noticeData && noticeData.seeNoticeInfo}
      noticeFile={fileData && fileData.seeNoticeFile}
      handleRemoveFile={handleRemoveFile}
      handleUpload={handleUpload}
      setFileChange={setFileChange}
      fileChange={fileChange}
    />
  );
}

export default NotificationContainer;
