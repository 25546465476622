import React from "react";
import colors from "../../../styles/colors";
import styled from "styled-components";

const CancelBoard = styled.div`
  width: 100%;
  padding: 20px 30px;
  margin-bottom: 30px;
  background-color: ${(props) => props.bGcolor};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const CountNum = styled.span`
  font-size: 40px;
  color: ${colors.whiteColor};
  vertical-align: baseline;
  text-align: right;
`;
const CountText = styled.span`
  color: ${colors.whiteColor};
  text-align: right;
  vertical-align: baseline;
`;

function InfoBox({ bGcolor, cancelCount, text, viewText }) {
  return (
    <CancelBoard bGcolor={bGcolor}>
      <CountText>{text}</CountText>
      <CountNum>{cancelCount}</CountNum>
    </CancelBoard>
  );
}

export default InfoBox;
