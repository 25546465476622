import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthProvider } from "./Context/AuthContext";
import Login from "./routes/Login";
import Home from "./routes/Home";
import User from "./routes/User";
import Auction from "./routes/Auction";
import Notification from "./routes/Notification";
import Search from "./routes/Search";

const LoggedInRoutes = () => (
  <Switch>
    {/* <Route exact path="/">
      <Redirect to="/Home" />
    </Route> */}
    <Route exact path="/" component={Home} />
    <Route path="/Home" component={Home} />
    <Route path="/User" component={User} />
    <Route path="/Auction" component={Auction} />
    <Route path="/Notification" component={Notification} />
    <Route path="/Search" component={Search} />
    {/* <Redirect from="*" to="/Home" /> */}
  </Switch>
);

const LoggedOutRoutes = () => (
  <Switch>
    <Route exact path="/Login" component={Login} />
    <Route exact path="/" component={Login} />
    {/* <Route exact path="/" component={Login} /> */}
  </Switch>
);

const AppRouter = ({ isLoggedIn }) => (
  <>
    {/* {console.log("isLoggedIn", isLoggedIn)} */}
    <AuthProvider>
      {isLoggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}
    </AuthProvider>
  </>
);

export default AppRouter;
