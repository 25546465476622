import React from "react";
import colors from "../../styles/colors";
import styled from "styled-components";
import Loader from "./Loader";

const ButtonWrap = styled.a`
  margin-left: 9px;
`;
const Button = styled.button`
  width: 80px;
  height: 40px;
  font-size: 16px;
  line-height: 16px;
  border: none;
  border-radius: 4px;
  text-align: center;
  color: ${colors.whiteColor};
  background-color: ${colors.activeGreen};
  outline: none;
  cursor: pointer;
`;

function ExcelButton({ excelData, loading = false }) {
  return (
    <ButtonWrap
      href={excelData && excelData.excelConvert ? excelData.excelConvert : null}
      target={"_blank"}
    >
      {loading ? <Loader /> : <Button>엑셀받기</Button>}
    </ButtonWrap>
  );
}

export default ExcelButton;
