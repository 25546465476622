import React from "react";
import styled from "styled-components";
import TableBodyDataDone from "./TableBodyDataDone";

const AuctionSuccessBox = styled.div``;

function AuctionSuccess({
  onSmsCheck,
  smsCheck,
  onFeesCheck,
  feesCheck,
  onTaxBillCheck,
  taxBillCheck,
  data = {},
  modalOpen,
  deleteCheck = false,
  onClickFeesCheck = () => null,
  onClickTaxBillCheck = () => null,
  onClickSmsCheck = () => null,
  setSelectSalesPost,
  setDeleteCheck = () => null,
  onClickDeleteCheck = () => null,

}) {
  return (
    <AuctionSuccessBox>
      <TableBodyDataDone
        onSmsCheck={onSmsCheck}
        deleteCheck={deleteCheck}
        smsCheck={smsCheck}
        onFeesCheck={onFeesCheck}
        feesCheck={feesCheck}
        onTaxBillCheck={onTaxBillCheck}
        taxBillCheck={taxBillCheck}
        setDeleteCheck={setDeleteCheck}
        key={data.id}
        data={data}
        modalOpen={modalOpen}
        setSelectSalesPost={setSelectSalesPost}
        {...data}
        onClickFeesCheck={onClickFeesCheck}
        onClickTaxBillCheck={onClickTaxBillCheck}
        onClickSmsCheck={onClickSmsCheck}
        onClickDeleteCheck={onClickDeleteCheck}
      />
    </AuctionSuccessBox>
  );
}

export default AuctionSuccess;
