import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";

const Select = styled.select`
  color: ${colors.inactiveColor};
  font-size: 16px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const DevideView = styled.div`
  ${(props) => props.titleCount && `width: calc(100% / ${props.titleCount});`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${colors.borderColor};
  padding: 12px 0;
`;
const ContentText = styled.span`
  color: ${colors.inactiveColor};
`;

function ContentsHeader({
  selectOption = [],
  headerTitle = [],
  setUserCategory = () => null,
}) {
  const titleCount =
    selectOption.length !== 0 ? headerTitle.length + 1 : headerTitle.length;
  return (
    <>
      {selectOption.length !== 0 && (
        <DevideView titleCount={titleCount} key={"first_Devied_View"}>
          <Select onChange={(e) => setUserCategory(e.target.value)}>
            {selectOption.map((props, index) => (
              <option
                key={"option" + index}
                value={
                  (props === "판매자" && "buyer") ||
                  (props === "구매자" && "seller") ||
                  (props === "구분 (전체)" && "all")
                }
              >
                {props}
              </option>
            ))}
          </Select>
        </DevideView>
      )}
      {headerTitle.length !== 0 &&
        headerTitle.map((props, index) => (
          <DevideView titleCount={titleCount} key={"ContentText" + index}>
            <ContentText>{props}</ContentText>
          </DevideView>
        ))}
    </>
  );
}

export default React.memo(ContentsHeader);
