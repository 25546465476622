import React, {useState} from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";

const UserBox = styled.div`
  width: 200px;
  //height: 74px;
  padding: 10px 50px 0 0;
  box-sizing: border-box;
  border-right: 1px solid ${colors.borderColor};
  white-space: nowrap;
`;
const InputBox = styled.div`
  width: 200px;
  //height: 74px;
  padding: 2px 30px 0 0;
  box-sizing: border-box;
  border-right: 1px solid ${colors.borderColor};
  white-space: nowrap;
`;
const UserName = styled.span`
  font-size: 32px;
  color: ${colors.darkestBlack};
  margin-right: 5px;
`;
const UserType = styled.span`
  font-size: 20px;
  color: ${colors.darkerGray};
`;
const UserInput = styled.input`
  font-size: 20px;
  width: 50%;
  color: ${colors.darkestBlack};
  margin-top: 10px;
  letter-spacing: 1px;
  border: 1px solid ${colors.borderColor};
  border-radius: 8px;
`;
const UserInput2 = styled.input`
  font-size: 20px;
  width: 100%;
  color: ${colors.darkestBlack};
  margin-top: 10px;
  letter-spacing: 1px;
  border: 1px solid ${colors.borderColor};
  border-radius: 8px;
`;

const TypeSelect = styled.select`
  color: ${colors.inactiveColor};
  width: 45%;
  font-size: 16px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
const Button = styled.button`
  width: 80px;
  height: 40px;
  border-radius: 12px;
  text-align: center;
  padding: 4px 10px;
  float: right;
  margin-top: 20px;
  background-color: darkgreen;
  border: 0px;
  color: ${colors.whiteColor};
  font-weight: bold;
  font-size: 16px;
  &:active {
    opacity: 0.4;
  }
  cursor: pointer;
`;
function UserInfo({
  id,
  name = "홍길동",
  phoneNumber = "01011111111",
  role,
  handelEditUser = () => null,
}) {
  const [ selectRole, setSelectRole ] = useState(role);
  const [ selectName, setSelectName ] = useState(name);
  const [ selectPhoneNum, setSelectPhoneNum ] = useState(phoneNumber);
  const handleName = (event) => {
    setSelectName(event.target.value);
  }

  const handlePhoneNum = (event) => {
    setSelectPhoneNum(event.target.value);
  }

  const handleRole = (event) => {
    setSelectRole(event.target.value);
  }

  return (
    <UserBox>
      <InputBox>
        <UserInput type="text" value={selectName} onChange={handleName} />
        <TypeSelect onChange={handleRole}>
          <option value="buyer" selected={selectRole === 'buyer'}>수출업자</option>
          <option value="seller" selected={selectRole === 'seller'}>딜러</option>
        </TypeSelect>
      </InputBox>
      <InputBox>
        <UserInput2 type="text" value={selectPhoneNum} onChange={handlePhoneNum} />
      </InputBox>
      <InputBox>
        <Button onClick={() => handelEditUser(selectName, selectPhoneNum, selectRole)}>수정</Button>
      </InputBox>
    </UserBox>
  );
}

UserInfo.defaultProps = {
  userName: "홍길동",
  userType: "판매자",
  userPhone: "010-1234-1234",
};

export default UserInfo;
