import React from "react";
import colors from "../../../styles/colors";
import styled from "styled-components";

const TableWrap = styled.div`
  width: 100%;
  padding: 8px 0;
`;
const TableLine = styled.div`
  width: 20%;
  padding: 10px 0;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  color: ${colors.darkerGray};
  /* font-weight: bold; */
  border-bottom: 1px solid ${colors.borderColor};
`;

function TableBodyData({
  id = "0",
  salesPosts = [],
  price = 0,
  seller = "모름",
  buyer = "모름",
}) {
  return (
    <TableWrap>
      <TableLine>
        {salesPosts.length !== 0 && salesPosts.managementNumber}
      </TableLine>
      <TableLine>{salesPosts.length !== 0 && salesPosts.carModel}</TableLine>
      <TableLine>
        {price !== ""
          ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " 만원"
          : "-"}
      </TableLine>
      <TableLine>{seller}</TableLine>
      <TableLine>{buyer}</TableLine>
    </TableWrap>
  );
}

export default TableBodyData;
