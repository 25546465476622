import React from "react";
import colors from "../../styles/colors";
import styled from "styled-components";
import Input from "./Input";
import ExcelButton from "./ExcelButton";

const TopBox = styled.div`
  width: 100%;
  height: 100px;
  border-bottom: 1px solid ${colors.borderColor};
  background-color: ${colors.emptyBackgroundColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
`;
const TopLeft = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const TopRight = styled.div`
  display: flex;
`;
const Title = styled.div`
  font-size: 16px;
  color: ${colors.darkestBlack};
`;
const SubTitleBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;
const SubTitle = styled.div`
  font-size: 14px;
  color: ${colors.darkestBlack};
`;
const Dot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0 7px 0 12px;
  background-color: ${(props) => props.bgColor};
`;
const SubText = styled.span`
  font-size: 14px;
  color: ${colors.inactiveColor};
`;
const SubTitleLine = styled.div`
  width: 1px;
  height: 15px;
  margin: 0 26px;
  background-color: ${colors.inactiveColor};
`;
const SubTitleNumber = styled.span`
  font-size: 28px;
  color: ${colors.darkestBlack};
  margin-left: 9px;
`;
const Today = styled.div`
  width: 160px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  color: ${colors.grayishColor};
  background-color: ${colors.whiteColor};
`;

function ContentHead({
  title,
  subTitle,
  bgColor,
  subText,
  dateString,
  totalUsers,
  userVisible,
  auctionVisible,
  notificationVisible,
  totalNotice,
  excelData,
  excelLoading = false,
}) {
  return (
    <TopBox>
      <TopLeft>
        <Title>{title}</Title>
        <SubTitleBox>
          <SubTitle>{subTitle}</SubTitle>
          <Dot bgColor={bgColor} />
          <SubText>{subText}</SubText>
          {userVisible && <SubTitleLine />}
          {userVisible && <SubText>총 유저수</SubText>}
          {userVisible && <SubTitleNumber>{totalUsers}</SubTitleNumber>}
          {auctionVisible && <SubText></SubText>}

          {notificationVisible && <SubTitleLine />}
          {notificationVisible && <SubText>총 공지수</SubText>}
          {notificationVisible && (
            <SubTitleNumber>{totalNotice}</SubTitleNumber>
          )}
        </SubTitleBox>
      </TopLeft>
      <TopRight>
        {dateString && <Today>{dateString}</Today>}
        {/* {(userVisible && <Input />) || (auctionVisible && <Input />)} */}
        {(auctionVisible || userVisible) && (
          <ExcelButton excelData={excelData} loading={excelLoading} />
        )}
      </TopRight>
    </TopBox>
  );
}

export default ContentHead;
