import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";

const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
`;
const ModalBlock = styled.div`
  width: 695px;
  height: 315px;
  background: ${colors.whiteColor};
  border-radius: 28px;
`;
const ModalHeader = styled.div`
  height: 100px;
  padding: 32px 36px 0 34px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
  display: flex;
  justify-content: space-between;
`;
const ModalTitle = styled.span`
  font-size: 32px;
  color: ${colors.darkestBlack};
`;
const SubTitle = styled.span`
  font-size: 20px;
  color: ${colors.borderColor};
  margin-left: 10px;
`;
const CloseButton = styled.div`
  font-size: 18px;
  color: ${colors.inactiveColor};
  cursor: pointer;
`;
const ModalContent = styled.div`
  height: 173px;
  border-bottom: ${colors.darkBorderColor};
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  padding: 20px;
  resize: none;
  font-size: 18px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 20px;
    color: ${colors.borderColor};
  }
`;
const SendButton = styled.button`
  width: 100%;
  height: 40px;
  color: ${colors.whiteColor};
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  border: none;
  ${(props) =>
    props.messageInput === ""
      ? `background-color: ${colors.borderColor};`
      : `background-color: ${colors.activeBlue};`}
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:active {
    opacity: 0.7;
    transition: 0.2s;
  }
`;

function UserMessageModal({
  messageModal,
  messageModalClose,
  smsList = [],
  messageInput,
  sendMessage,
}) {
  if (!messageModal) return null;
  return (
    <DarkBackground>
      <ModalBlock>
        <ModalHeader>
          <ModalTitle>
            {smsList.length !== 0 ? smsList[0] : "문자 보낼 번호를 체크하세요"}
            <SubTitle>외 {smsList.length}명</SubTitle>
          </ModalTitle>

          <CloseButton onClick={messageModalClose}>닫기</CloseButton>
        </ModalHeader>
        <ModalContent>
          <TextArea
            placeholder="...메세지를 입력하세요 / 40자 이내"
            maxLength={40}
            rows={4}
            {...messageInput}
          />
          <SendButton
            messageInput={messageInput.value}
            onClick={() => sendMessage()}
          >
            전송하기
          </SendButton>
        </ModalContent>
      </ModalBlock>
    </DarkBackground>
  );
}

export default UserMessageModal;
