import React from "react";
import colors from "../../styles/colors";
import styled, { keyframes } from "styled-components";

const SlideBar = keyframes`
    from {
        width: 0;
    }
    to {
        width: ${(props) => props.percent}%;
    }
`;

const OutLine = styled.div`
  width: 180px;
  height: 10px;
  border-radius: 5px;
  border: 1px solid ${colors.borderColor};
  transition: 0.8s ease-in;
  position: relative;
  margin: 0 auto;
`;
const Gauge = styled.div`
  width: ${(props) => props.percent}%;
  height: 10px;
  background-color: ${(props) =>
    props.color ? props.color : colors.inactiveColor};
  border-radius: 5px;
  animation: ${SlideBar} 1s ease-out;
  position: absolute;
  top: -1px;
  left: -1px;
`;

function BarGraph({ percent, color }) {
  return (
    <OutLine>
      <Gauge percent={percent} color={color} />
    </OutLine>
  );
}

export default BarGraph;
