import React from "react";
import { toast } from "react-toastify";
import useInput from "../../hooks/useInput";
import LoginPresenter from "./LoginPresenter";
import { useLogIn } from "../../Context/AuthContext";
import { useMutation } from "@apollo/client";
import { ADMIN_LOGIN } from "../../graphql/auth/mutation";
import { useHistory } from "react-router";

const LoginContainer = () => {
  const idInput = useInput("");
  const pwInput = useInput("");
  const logIn = useLogIn();
  const history = useHistory();

  console.log("Login history", history);

  const [adminLoginMutation] = useMutation(ADMIN_LOGIN);

  const onSubmit = async () => {
    try {
      console.log("로그인 실행");
      const { data } = await adminLoginMutation({
        variables: {
          id: idInput.value,
          pw: pwInput.value,
        },
      });
      console.log("로그인 실행 데이타", data);
      if (data) {
        console.log("로그인 실행");
        logIn(data.adminLogin);
        window.setTimeout(() => {
          history.push({
            pathname: "/Home",
          });
          window.location.reload();
        }, 200);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <LoginPresenter idInput={idInput} pwInput={pwInput} onSubmit={onSubmit} />
    </>
  );
};

export default LoginContainer;
