import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
    ${reset};
    * {
        box-sizing:border-box;
    }
    body {
        padding-top: 60px;
        font-size: 16px;
        background-color : #EDF0F5
    }
    a {
        text-decoration:none;
    }
    input:focus{
        outline:none;
    }
`;
