import { useQuery } from "@apollo/client";
import React, {useState} from "react";
import {
  ADMIN_ALL_SALES_POST_COUNT,
  ADMIN_ALL_USER_COUNT,
  TODAY_TOTAL_SALES,
} from "../../graphql/auction/query";
import HomePresenter from "./HomePresenter";
import {SEE_ADMIN_USER} from "../../graphql/user/query";

const HomeContainer = () => {
  const { loading, data, error } = useQuery(ADMIN_ALL_SALES_POST_COUNT);
  const {
    loading: userLoading,
    data: userCountData,
    error: userCountError,
  } = useQuery(ADMIN_ALL_USER_COUNT, {
    pollInterval: 5000,
  });

  const { data: adminData, loading: adminLoading, error: adminError } = useQuery(SEE_ADMIN_USER);
  const [passChangeModal, setPassChangeModal] = useState(false);


  const {
    data: todayData,
    loading: todayLoading,
    error: todayError,
  } = useQuery(TODAY_TOTAL_SALES, {
    pollInterval: 5000,
  });

  console.log("todayData", todayData);
  console.log(userCountData);
  const today = new Date();
  const dateString = today.toLocaleDateString("ko-KR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const passModalOpen = () => {
    console.log("PASS모달 실행");
    setPassChangeModal(true);
  };
  const passModalClose = () => {
    setPassChangeModal(false);
  };

  // TestData
  return (
    <HomePresenter
      dateString={dateString}
      salesCountData={data && data.adminAllSalesPostCount}
      userCountData={userCountData && userCountData.adminAllUserCount}
      todayData={todayData && todayData.todayTotalSales}
      adminData={adminData && adminData.seeAllAdmin}
      passChangeModal={passChangeModal}
      passModalOpen={passModalOpen}
      passModalClose={passModalClose}
    />
  );
};

export default HomeContainer;
