import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import UserInfo from "../../share/Modal/UserInfo";
import ModalButton from "../../share/Modal/ModalButton";

const ModalBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const UserHistory = styled.div`
  width: 400px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
const CountBox = styled.div`
  width: 20%;
  text-align: center;
`;
const CountNumber = styled.div`
  font-size: 32px;
  color: ${colors.darkestBlack};
  text-align: center;
`;
const CountText = styled.div`
  font-size: 16px;
  color: ${colors.darkerGray};
  margin-top: 5px;
`;
const ButtonGroup = styled.div`
  width: 145px;
`;

const CancelCount = styled.div`
  width: 145px;
  margin-bottom: 11px;
  text-align: center;
  color: ${colors.lightOrange};
  font-weight: bold;
`;

const ResetButtonBox = styled.div`
  margin-top: 10px;
`;


function SellerDetail({
  TotalSellCount = 23,
  BiddngCount = 2,
  FailedCount = 3,
  stopedCount = 0,
  Done = 4,
  id,
  name,
  phoneNumber,
  role,
  stoped,
  handleStopUser,
  handelCancelResetStopped = () => null,
  handelEditUser = () => null,
  data = {
    all: 0,
    cancel: 0,
    complete: 0,
    observation: 0,
    register: 0,
    trading: 0,
  },
}) {
  console.log(stopedCount);
  const { all, cancel, complete, observation, register, trading } = data;
  // console.log("SellerDetail", data);
  return (
    <ModalBody>
      <UserInfo id={id} name={name} phoneNumber={phoneNumber} role={role} handelEditUser={handelEditUser}/>
      <UserHistory>
        <CountBox>
          <CountNumber>{all}</CountNumber>
          <CountText>총 판매글</CountText>
        </CountBox>
        <CountBox>
          <CountNumber>{trading}</CountNumber>
          <CountText>거래중</CountText>
        </CountBox>
        <CountBox>
          <CountNumber>{cancel ? cancel : "0"}</CountNumber>
          <CountText>유찰</CountText>
        </CountBox>
        <CountBox>
          <CountNumber>{complete}</CountNumber>
          <CountText>거래완료</CountText>
        </CountBox>
      </UserHistory>
      <ButtonGroup>
        <CancelCount>정지 횟수 : {stopedCount}</CancelCount>
        {!stoped ? (
          <ModalButton
            color={colors.lightOrange}
            borderColor={colors.lightOrange}
            bgColor={colors.whiteColor}
            btnText="정지하기"
            onClick={() => handleStopUser(id, stoped)}
          />
        ) : (
          <ModalButton
            color={colors.whiteColor}
            bgColor={colors.activeBlue}
            btnText="정지풀기"
            onClick={() => handleStopUser(id, stoped)}
          />
        )}

        <ResetButtonBox>
          {/* <CancelCount>정지 횟수 : {stopedCount}</CancelCount> */}
          <ModalButton
            color={colors.whiteColor}
            bgColor={colors.lightOrange}
            btnText=" 정지 내역 초기화"
            onClick={handelCancelResetStopped}
          ></ModalButton>
        </ResetButtonBox>
      </ButtonGroup>
    </ModalBody>
  );
}

export default SellerDetail;
