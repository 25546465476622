import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';

const Label = styled.label`
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 4px;
  border: 1px solid ${colors.activeBlue};
  background-color: ${props => props.smsCheck && `${colors.activeBlue}`};
  cursor: pointer;
`;
const InputCheck = styled.input`
  display: none;
`;

function CheckBox({
  smsCheck,
  onSmsCheck,
  feesCheck,
  onFeesCheck,
  taxBillCheck,
  onTaxBillCheck
}) {
  const [checked, setChecked] = useState({
    smsChecked: false,
    feesCheck: false,
    taxBillCheck: false,
  });
  if (smsCheck) {
    setChecked({
      smsChecked: true,
      feesCheck: false,
      taxBillCheck: false,
    });
  }
  if (feesCheck) {
    setChecked({
      smsChecked: false,
      feesCheck: true,
      taxBillCheck: false,
    });
  }
  if (taxBillCheck) {
    setChecked({
      smsChecked: false,
      feesCheck: false,
      taxBillCheck: true,
    });
  }

  return (
    <Label checked={checked}>
      <InputCheck type="checkbox" onChange={
        // () => {
        // onSmsCheck;
        // onFeesCheck;
        // onTaxBillCheck;
        onSmsCheck
      } />
    </Label>
  );
}

export default CheckBox;
