import {useMutation, useQuery} from "@apollo/client";
import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {CONFIRM_USER} from "../../graphql/auction/mutation";
import {ADMIN_ALL_USER_COUNT, EXCEL_CONVERT,} from "../../graphql/auction/query";
import {SEND_MASS_SMS, STOP_USER} from "../../graphql/user/mutation";
import {SEE_ALL_USER} from "../../graphql/user/query";
import useInput from "../../hooks/useInput";
import UserPresenter from "./UserPresenter";

function UserContainer() {
  const [messageModal, setMessageModal] = useState(false);
  const [smsAllCheck] = useState(false);
  const [stopUserModal, setStopUserModal] = useState(false);
  const [userInfoModal, setUserInfoModal] = useState(false);
  const [userCategory, setUserCategory] = useState("all");
  const [stopUserCategory, setStopUserCategory] = useState(false);
  const [confirmUserCategory, setConfirmUserCategory] = useState(false);
  const [selectUserData, setSelectUserData] = useState({});
  const [seeAllUserCondition, setSeeAllUserCondition] = useState({
    role: "all",
    stoped: false,
    adminConfirm: false,
  });
  console.log("seeAllUserCondition", seeAllUserCondition);
  const modalEl = useRef();
  const [smsList, setSmsList] = useState([]);
  const messageInput = useInput("");
  const { data, loading, error, refetch } = useQuery(SEE_ALL_USER, {
    variables: {
      role: seeAllUserCondition.role,
      stoped: seeAllUserCondition.stoped,
      adminConfirm: !seeAllUserCondition.adminConfirm,
    },
    pollInterval: 5000,
  });

  console.log("see All User data 실행", data);

  const handleSmsAllCheck = () => {
    console.log("체크 실행");
    console.log("전체 데이터 넣기 실행", smsList);
    if (data && data.seeAllUser && data.seeAllUser.users) {
      console.log("루프 실행");
      let newSmsListArray = [];
      for (let i = 0; i < data.seeAllUser.users.length; i++) {
        newSmsListArray.push(data.seeAllUser.users[i].phoneNumber);
        console.log("유저 폰넘버 실행", data.seeAllUser.users[i].phoneNumber);
      }
      setSmsList([...newSmsListArray]);
      console.log("루프 후", smsList);
      toast.success("전체 문자를 보낼 수 있습니다.");
      setMessageModal(true);
    } else {
      return toast.error(
          "정보량이 많이 시간이 걸려요. 잠시 후 다시 시도해주세요."
      );
    }
  };

  // console.log(data);

  const { data: adminCountData, error: adminCountError } = useQuery(
      ADMIN_ALL_USER_COUNT,
      {
        pollInterval: 5000,
      }
  );

  // console.log(adminCountData);

  const {
    data: excelData,
    loading: excelLoading,
    error: excelError,
  } = useQuery(EXCEL_CONVERT, {
    variables: {
      tableName: "User",
    },
  });

  const [confirmUserMutation] = useMutation(CONFIRM_USER);

  const handleConfirmUser = async (userId, status) => {
    if (status) {
      return toast("이 유저는 이미 인증되었습니다");
    }
    try {
      const result = confirmUserMutation({
        variables: {
          userId,
        },
      });
      if (result) {
        return toast.success("유저 인증이 완료되었습니다.");
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };

  const [stopUserMustation] = useMutation(STOP_USER);
  const handleStopUser = async (userId, stoped) => {
    console.log(userId);
    if (stoped) {
      const confirmResult = await window.confirm("정지를 해제하시겠어요?");
      if (confirmResult) {
        const result = await stopUserMustation({
          variables: {
            userId,
            stoped: false,
          },
          refetchQueries: [
            {
              query: SEE_ALL_USER,
              variables: {
                role: userCategory,
              },
            },
            {
              query: ADMIN_ALL_USER_COUNT,
            },
          ],
        });
        if (result) {
          return toast.success("유저의 정지가 해제되었습니다.");
        }
      }
    } else if (!stoped) {
      const confirmResult = await window.confirm("이 유저를 정지하시겠어요?");
      if (confirmResult) {
        const result = await stopUserMustation({
          variables: {
            userId,
            stoped: true,
          },
          refetchQueries: [
            {
              query: SEE_ALL_USER,
              variables: {
                role: userCategory,
              },
            },
          ],
        });
        if (result) {
          return toast.success("이 유저는 정지되었습니다.");
        }
      }
    }
  };

  // console.log(adminCountData);

  useEffect(() => refetch(), [seeAllUserCondition]);

  const [sendMessageMutation] = useMutation(SEND_MASS_SMS);

  // console.log("userCategory", userCategory);

  const onClickSmsCheck = (state, phoneNumber) => {
    console.log("실행");
    if (state) {
      setSmsList(smsList.filter((prevNumber) => phoneNumber !== prevNumber));
    }
    if (!state) {
      setSmsList([...smsList, phoneNumber]);
    }
  };
  console.log("smsList", smsList);

  const sendMessage = async () => {
    if (messageInput.value === "") {
      return alert("메시지를 입력해주세요");
    }
    if (smsList.length === 0) {
      return window.alert("문자 보낼 번호를 선택하세요.");
    }
    try {
      const result = await sendMessageMutation({
        variables: {
          phoneNumbers: smsList,
          msg: messageInput.value,
        },
      });
      if (result) {
        return (
            window.alert("메시지가 전송되었습니다."),
                setMessageModal(!messageModal)
        );
      }
    } catch (e) {
      toast(e.message);
      console.log(e);
    }
  };

  if (error) {
    console.log(error);
  }

  // eslint-disable-next-line
  const handleClickOutsideModal = (e) => {
    // if (messageModal && !modalEl.current.contains(e.target)) setMessageModal(false);
  };
  // console.info(modalEl.current.contains());
  // console.info(modalEl.current);

  const messageModalOpen = () => {
    setMessageModal(true);
  };
  const messageModalClose = () => {
    setMessageModal(false);
    setSmsList([]);
    // window.location.reload();
  };
  const stopUserModalOpen = () => {
    setStopUserModal(true);
  };
  const stopUserModalClose = () => {
    setStopUserModal(false);
  };
  const userInfoModalOpen = (data) => {
    console.log("인포모달 실행");
    setSelectUserData(data);
    setUserInfoModal(true);
  };
  const userInfoModalClose = () => {
    setUserInfoModal(false);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutsideModal);
    return () => {
      window.removeEventListener("click", handleClickOutsideModal);
    };
  }, [handleClickOutsideModal]);

  return (
      <UserPresenter
          messageModal={messageModal}
          setSeeAllUserCondition={setSeeAllUserCondition}
          messageModalOpen={messageModalOpen}
          messageModalClose={messageModalClose}
          stopUserModal={stopUserModal}
          stopUserModalOpen={stopUserModalOpen}
          stopUserModalClose={stopUserModalClose}
          userInfoModal={userInfoModal}
          userInfoModalOpen={userInfoModalOpen}
          userInfoModalClose={userInfoModalClose}
          modalEl={modalEl}
          data={data && data.seeAllUser && data.seeAllUser}
          onClickSmsCheck={onClickSmsCheck}
          smsList={smsList}
          messageInput={messageInput}
          sendMessage={sendMessage}
          adminCountData={adminCountData}
          excelData={excelData}
          handleStopUser={handleStopUser}
          userCategory={userCategory}
          setUserCategory={setUserCategory}
          stopUserCategory={stopUserCategory}
          setStopUserCategory={setStopUserCategory}
          confirmUserCategory={confirmUserCategory}
          setConfirmUserCategory={setConfirmUserCategory}
          selectUserData={selectUserData}
          setSelectUserData={setSelectUserData}
          handleConfirmUser={handleConfirmUser}
          excelLoading={excelLoading}
          smsAllCheck={smsAllCheck}
          handleSmsAllCheck={handleSmsAllCheck}
      />
  );
}

export default UserContainer;
