import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { SEARCH_SALES_POST, SEARCH_USER } from "../../graphql/search/query.js";
import { SEND_MASS_SMS } from "../../graphql/user/mutation.js";
import useInput from "../../hooks/useInput.js";
import SearchPresenter from "./SearchPresenter.js";

function SearchContainer() {
  const [messageModal, setMessageModal] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [modal, setModal] = useState(false);
  const [detailView, setDetailView] = useState(false);
  const [smsList, setSmsList] = useState([]);
  const messageInput = useInput("");
  const searchInput = useInput("");
  const [toggleSearchUser, setToggleSearchUser] = useState(false);
  const [selectSalesPost, setSelectSalesPost] = useState({});

  const [sendMessageMutation] = useMutation(SEND_MASS_SMS);

  const { data, error, loading, refetch } = useQuery(SEARCH_SALES_POST, {
    variables: {
      term: searchInput.value,
    },
  });
  const {
    data: userData,
    error: userError,
    loading: userLoading,
    refetch: userRefetch,
  } = useQuery(SEARCH_USER, {
    variables: {
      term: searchInput.value,
    },
  });

  if (error || userError) {
    toast(error);
    toast(userError);
    console.log(error || userError);
  }

  const onClickSmsCheck = (state, phoneNumber) => {
    console.log("박스 체크 실행");
    if (state) {
      setSmsList(smsList.filter((prevNumber) => phoneNumber !== prevNumber));
    }
    if (!state) {
      setSmsList([...smsList, phoneNumber]);
    }
  };

  const sendMessage = async () => {
    if (messageInput.value === "") {
      return alert("메시지를 입력해주세요");
    }
    if (smsList.length === 0) {
      return window.alert("문자 보낼 번호를 선택하세요.");
    }
    try {
      const result = await sendMessageMutation({
        variables: {
          phoneNumbers: smsList,
          msg: messageInput.value,
        },
      });
      if (result) {
        return (
          window.alert("메시지가 전송되었습니다."),
          setMessageModal(!messageModal)
        );
      }
    } catch (e) {
      toast(e.message);
      console.log(e);
    }
  };

  const messageModalOpen = () => {
    console.log("실행");
    setMessageModal(true);
  };
  const messageModalClose = () => {
    console.log("실행");
    setMessageModal(false);
  };

  // 매물 상세 정보 자세히보기
  const detailViewClick = () => {
    setDetailView(!detailView);
  };
  // 모달 열기
  const modalOpen = () => {
    setModal(true);
  };
  const modalClose = () => {
    setModal(false);
  };

  return (
    <>
      <SearchPresenter
        smsCheck={smsCheck}
        detailView={detailView}
        detailViewClick={detailViewClick}
        modal={modal}
        modalOpen={modalOpen}
        modalClose={modalClose}
        onClickSmsCheck={onClickSmsCheck}
        sendMessage={sendMessage}
        messageModalOpen={messageModalOpen}
        messageModalClose={messageModalClose}
        smsList={smsList}
        messageInput={messageInput}
        messageModal={messageModal}
        searchInput={searchInput}
        toggleSearchUser={toggleSearchUser}
        setToggleSearchUser={setToggleSearchUser}
        data={data}
        userData={userData}
        selectSalesPost={selectSalesPost}
        setSelectSalesPost={setSelectSalesPost}
      />
    </>
  );
}

export default SearchContainer;
