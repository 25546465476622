import { gql } from "@apollo/client";

export const CHANGE_ADMIN_ACCOUNT = gql`
  mutation noticeInfoEdit(
    $noticeId: Int!
    $account: String
    $adminPhoneNumber: String
  ) {
    noticeInfoEdit(
      noticeId: $noticeId
      account: $account
      adminPhoneNumber: $adminPhoneNumber
    ) {
      id
    }
  }
`;
export const CHANGE_ADMIN_PHONENUMBER = gql`
  mutation noticeInfoEdit(
    $noticeId: Int!
    $account: String
    $adminPhoneNumber: String
  ) {
    noticeInfoEdit(
      noticeId: $noticeId
      account: $account
      adminPhoneNumber: $adminPhoneNumber
    ) {
      id
    }
  }
`;

export const NOTICE_FILE_DELETE = gql`
  mutation noticeFileDelete($noticeId: Int!) {
    noticeFileDelete(noticeId: $noticeId)
  }
`;
export const NOTICE_FILE_CREATE = gql`
  mutation noticeFileCreate($noticeImage: String) {
    noticeFileCreate(noticeImage: $noticeImage)
  }
`;
