import { gql } from "@apollo/client";

export const ADD_COMMISSION_TAX = gql`
  mutation addCommissionTax(
    $salesPostId: Int
    $commission: Boolean
    $tax: Boolean
  ) {
    addCommissionTax(
      salesPostId: $salesPostId
      commission: $commission
      tax: $tax
    )
  }
`;

export const DELETE_SALES_POST = gql`
  mutation deleteSalesPost($salesPostId: [Int!]) {
    deleteSalesPost(salesPostId: $salesPostId)
  }
`;

export const ADD_TAX = gql`
  mutation addTax($salesPostId: [Int!]) {
    addTax(salesPostId: $salesPostId)
  }
`;

export const ADD_COMMISSION = gql`
  mutation addCommission($salesPostId: [Int!]) {
    addCommission(salesPostId: $salesPostId)
  }
`;

export const FORCE_OBSERVATION = gql`
  mutation forcedObservation($salesPostId: Int) {
    forcedObservation(salesPostId: $salesPostId)
  }
`;
export const TRADE_COMPLETE = gql`
  mutation tradeComplete($salesPostId: Int, $biddingId: Int, $nameDocumentUrl: String, $discountReason: String) {
    tradeComplete(salesPostId: $salesPostId, biddingId: $biddingId, nameDocumentUrl: $nameDocumentUrl, discountReason: $discountReason)
  }
`;
export const CONFIRM_USER = gql`
  mutation confirmUser($userId: Int) {
    confirmUser(userId: $userId)
  }
`;

export const EDIT_BIDDING = gql`
  mutation editBidding($biddingId: Int, $feedbackScore: Int, $feedbackReason: String) {
    editBidding(biddingId: $biddingId, feedbackScore: $feedbackScore, feedbackReason: $feedbackReason) {
      id
      feedbackScore
      feedbackReason
    }
  }
`;
