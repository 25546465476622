import { gql } from "@apollo/client";

export const SEARCH_SALES_POST = gql`
  query searchSalesPost($take: Int, $cursor: Int, $term: String) {
    searchSalesPost(take: $take, cursor: $cursor, term: $term) {
      salesPosts {
        id
        createdAt
        updatedAt
        managementNumber
        manufacturer
        carModel
        carYear
        carColor
        fuelType
        distance
        carGrade
        carNumber
        transmission
        area
        option {
          id
          optionSmartKey
          option4WD
          optionSunLoop
          optionNavigation
          optionAroundView
          optionBrownSeat
        }
        description
        completedDate
        status
        commissionConfirm
        taxBill
        seller {
          id
          name
          phoneNumber
          role
          stopedCount
          stoped
        }
        auctionDeadline
        biddingCount
        biddings {
          id
          createdAt
          price
          buyer {
            id
            name
            phoneNumber
            company
          }
          condition
        }
      }
      salesPostCount
      preCursor
    }
  }
`;

export const SEARCH_USER = gql`
  query searchUser($take: Int, $cursor: Int, $term: String) {
    searchUser(take: $take, cursor: $cursor, term: $term) {
      users {
        id
        createdAt
        updatedAt
        name
        phoneNumber
        company
        role
        authImage
        stoped
        stopedCount
        adminConfirm
      }
      userCount
      preCursor
    }
  }
`;
