import { gql } from "@apollo/client";

export const SEND_MASS_SMS = gql`
  mutation sendMassSMS($phoneNumbers: [String!], $msg: String) {
    sendMassSMS(phoneNumbers: $phoneNumbers, msg: $msg)
  }
`;
export const STOP_USER = gql`
  mutation stopUser($userId: Int, $stoped: Boolean) {
    stopUser(userId: $userId, stoped: $stoped)
  }
`;
export const ADMIN_EDIT_USER = gql`
  mutation adminEditUser($userId: Int, $name: String, $phoneNumber: String, $company: String, $role: Role) {
    adminEditUser(userId: $userId, name: $name, phoneNumber: $phoneNumber, company: $company, role: $role)
  }
`;
export const ADMIN_RESET_CANCEL = gql`
  mutation adminResetCancel($userId: Int) {
    adminResetCancel(userId: $userId)
  }
`;

export const ADMIN_RESET_STOPPED = gql`
  mutation adminResetStopped($userId: Int) {
    adminResetStopped(userId: $userId)
  }
`;

export const ADMIN_PASSWORD_CHANGE_BY_ID = gql`
  mutation changePasswordById($userId: Int, $password: String) {
    changePasswordById(userId: $userId, password: $password)
  }
`;

export const ADMIN_COMMAND_PM2 = gql`
  mutation commandPm2($command: String) {
    commandPm2(command: $command)
  }
`;
