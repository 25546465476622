import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import Colors from "./styles/colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./Context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/layout/Header";
import SideBar from "./components/layout/SideBar";
import AppRouter from "./Router";
import {
  InMemoryCache,
  HttpLink,
  ApolloClient,
  ApolloLink,
  concat,
} from "@apollo/client";
import ServerIP from "./apollo/ServerIP";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [client, setClient] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  const previousLoading = async () => {
    try {
      const cache = new InMemoryCache();
      // const token =
      //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAsImNyZWF0ZWRBdCI6IjIwMjEtMDItMTZUMDE6Mzg6MzMuMDc5WiIsInVwZGF0ZWRBdCI6IjIwMjEtMDItMTZUMDE6Mzg6MzMuMjc1WiIsIm5hbWUiOiLrsJXrj5ntmIQiLCJwaG9uZU51bWJlciI6IjAxMDM4MDE5MjIyIiwibG9naW5TZWNyZXRDb2RlIjoiMTU3MDY0IiwiY29tcGFueSI6bnVsbCwicm9sZSI6InNlbGxlciIsImF1dGhJbWFnZSI6Imh0dHA6Ly8xOTIuMTY4LjAuODc6OTc1NS8xNjEzNDM5NTEyOTcwLUJFOURGRkJCLTlCNTMtNEY3Ni05NzNGLUY0NDkwOTQzOUIwQS5wbmciLCJzdG9wZWQiOmZhbHNlLCJzdG9wZWRDb3VudCI6MCwiYWRtaW5Db25maXJtIjp0cnVlLCJjYW5jZWxDb3VudCI6MCwiaWF0IjoxNjEzNDM5NTIzfQ.WeaAnObIzunK_A3LJVrdc0HJnk7RnNZ5bYOjgghS4tk";
      // 새로운 코드 헤더에 토큰 얹어서 보내기

      const token = await localStorage.getItem("carAuctionjwt");
      console.log("token", token);
      // console.log("token", token);
      const httpLink = new HttpLink({ uri: ServerIP });
      const authMiddleware = new ApolloLink((operation, forward) => {
        operation.setContext({
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        });
        return forward(operation);
      });
      const client = new ApolloClient({
        cache,
        link: concat(authMiddleware, httpLink),
      });
      const isLoggedIn = await localStorage.getItem("isLoggedIn");
      if (!isLoggedIn || isLoggedIn === "false") {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
      }
      setLoaded(true);
      setClient(client);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    previousLoading();
  }, []);
  //여기 isLoggedIn 참이면 홈화면, 아니면 로그인
  // const isLoggedIn = false;
  return (
    <ThemeProvider theme={Colors}>
      <AuthProvider>
        <BrowserRouter>
          <GlobalStyles />
          <Header isLoggedIn={isLoggedIn} />
          <SideBar isLoggedIn={isLoggedIn} />
          <AppRouter isLoggedIn={isLoggedIn} />
          {/* <ToastContainer position={toast.POSITION.TOP_RIGHT} /> */}
        </BrowserRouter>
      </AuthProvider>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </ThemeProvider>
  );
}

export default App;
