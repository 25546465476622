import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";

const SmsButtonWrap = styled.div`
  display: inline-block;
`;
const Button = styled.button`
  /* width: 80px; */
  height: 30px;
  margin-right: 8px;
  margin-left: 40px;
  color: ${colors.whiteColor};
  border: none;
  border-radius: 4px;
  background-color: ${colors.activeBlue};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.color === "red" && `background-color: ${colors.activeRed};`}
`;
const Text = styled.span``;

function SmsButton({ messageModalOpen, title = "보내기", color = "blue" }) {
  return (
    <SmsButtonWrap>
      <Button onClick={messageModalOpen} color={color}>
        <Text>{title}</Text>
      </Button>
    </SmsButtonWrap>
  );
}

export default SmsButton;
