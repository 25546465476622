import React from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import TableBodyData from './TableBodyData';

const AuctionFailedBox = styled.div``;

function AuctionFailed({ onSmsCheck, smsCheck }) {
  return (
    <AuctionFailedBox>
      <TableBodyData onSmsCheck={onSmsCheck} smsCheck={smsCheck} />
      유찰 수
    </AuctionFailedBox>
  );
}

export default AuctionFailed;
