import React from "react";
import styled from "styled-components";
import Logo from "../../assets/Logo.png";
import HorizontalLogo from "../../assets/horizontalLogo.png";
import Colors from "../../styles/colors";
import { useLogOut } from "../../Context/AuthContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useState } from "react";

const View = styled.div`
  width: 100%;
  min-width: 1200px;
  display: flex;
  background-color: ${Colors.darkestBlack};
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 60px;
`;
const A = styled.a`
  cursor: posinter;
  align-items: center;
  display: flex;
  margin-bottom: -8px;
`;
const Image = styled.div`
  cursor: posinter;
  width: 128px;
  height: 32px;
  background-image: url(${HorizontalLogo});
  background-repeat: no-repeat;
  background-size: contain;
`;
const Admin = styled.span`
  color: ${Colors.whiteColor};
  font-size: 1rem;
  margin-bottom: 0px;
  font-weight: 400;
`;
const Text = styled.button`
  background-color: inherit;
  border: 0px;
  outline: 0px;
  cursor: pointer;
  color: ${Colors.whiteColor};
  font-size: 1rem;
  margin-bottom: -6px;
  &:hover {
    color: ${Colors.activeBlue};
    transform: scale(1.02);
    font-weight: 600;
  }
`;

function Header({ isLoggedIn }) {
  const history = useHistory();
  const userLogOut = useLogOut();
  const handleLogOut = () => {
    const r = window.confirm("정말 로그아웃 하시겠어요?");
    if (r) {
      userLogOut();
      toast("로그아웃되었습니다.");
      window.setTimeout(() => {
        history.push({
          pathname: "/Login",
        });
        window.location.reload();
        // history.replace("/Login");
      }, 200);
    }
  };
  return (
    <>
      <View>
        <A href={""}>
          <Image />
          <Admin>ADMIN</Admin>
        </A>
        {isLoggedIn && <Text onClick={handleLogOut}>로그아웃</Text>}
      </View>
    </>
  );
}

export default React.memo(Header);
