import React, {useState} from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import { IoIosArrowDown } from "react-icons/io";
import DetailInfoTable from "./DetailInfoTable";
import UserInfo from "../../share/Modal/UserInfo";
import BiddingStatus from "./BiddingStatus";
import {
  IoStarOutline,
  IoStar
} from "react-icons/io5";

const DetialInfoWrap = styled.div`
  width: 100%;
`;
const SellerWrap = styled.div`
  width: 100%;
  height: 58%;
  display: flex;
  margin-top: 60px;
  position: relative;
  overflow: ${(props) => (props.detailView ? "visible" : "hidden")};
`;
const CarInfoBox = styled.div`
  width: 80%;
  padding: 10px 33px 0 55px;
`;
const InfoTopLine = styled.div`
  display: flex;
  align-items: center;
`;
const InfoTopTitle = styled.span`
  color: ${colors.inactiveColor};
  margin-right: 200px;
`;
const DetailView = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.darkBorderColor};
  cursor: pointer;
`;
const DetailViewIcon = styled.span`
  font-size: 28px;
`;
const FailButton = styled.button`
  width: 145px;
  height: 40px;
  color: ${colors.lightOrange};
  border: 1px solid ${colors.lightOrange};
  border-radius: 12px;
  background: ${colors.whiteColor};
  cursor: pointer;
  position: absolute;
  top: 100px;
  right: 10px;
  ${(props) => props.detailView && `display: none;`};
`;

const SuccessButton = styled.button`
  width: 145px;
  height: 40px;
  color: lightgreen;
  border: 1px solid lightgreen;
  border-radius: 12px;
  background: ${colors.whiteColor};
  cursor: pointer;
  position: absolute;
  //top: 100px;
  right: 10px;
  ${(props) => props.detailView && `display: none;`};
`;
const BiddingStatusBox = styled.div`
  border-top: 2px solid ${colors.darkBorderColor};
  padding-top: 30px;
`;
const BiddingTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.darkestBlack};
  margin-bottom: 20px;
`;
const TableBox = styled.div`
  width: 100%;
  display: flex;
  padding: 0 20px;
`;
const DevideView = styled.div`
  width: calc(100% / 8);
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${colors.borderColor};
  padding: 12px 0;
`;
const TebleText = styled.span`
  color: ${colors.inactiveColor};
`;
const TableBody = styled.div``;

function DetailInfo({
  id,
  companyName,
  discountReason,
  bidAmount,
  bidState,
  cancelReason,
  condition,
  dealDate,
  status,
  description,
  state,
  detailView,
  detailViewClick,
  seller,
  managementNumber,
  manufacturer,
  carModel,
  carYear,
  carColor,
  fuelType,
  distance,
  carGrade,
  carNumber,
  transmission,
  selectBidding,
  nameDocumentUrl,
  area,
  option,
  files = [],
  biddings = [],
  handleObservation = () => null,
  handleTradeComplete = () => null,
  handleSelectBidding = () => null,
  modalClose,
}) {

  return (
    <DetialInfoWrap>
      <SellerWrap detailView={detailView}>
        <UserInfo {...seller} />
        <CarInfoBox>
          <InfoTopLine>
            <InfoTopTitle>차량정보</InfoTopTitle>
            <DetailView onClick={detailViewClick}>
              자세히 보기
              <DetailViewIcon>
                <IoIosArrowDown />
              </DetailViewIcon>
            </DetailView>
          </InfoTopLine>
          <FailButton
            detailView={detailView}
            onClick={() => handleObservation(id)}
          >
            유찰하기
          </FailButton>
          <SuccessButton
            detailView={detailView}
            onClick={() => handleTradeComplete(id, selectBidding, nameDocumentUrl, discountReason)}
          >
            완료하기
          </SuccessButton>
          <DetailInfoTable
            managementNumber={managementNumber}
            manufacturer={manufacturer}
            carModel={carModel}
            carYear={carYear}
            carColor={carColor}
            fuelType={fuelType}
            distance={distance}
            carGrade={carGrade}
            carNumber={carNumber}
            transmission={transmission}
            area={area}
            option={option}
            files={files}
            description={description}
            discountReason={discountReason}
          />
        </CarInfoBox>
      </SellerWrap>

      {!detailView && (
        <BiddingStatusBox>
          <BiddingTitle>입찰 현황</BiddingTitle>
          <TableBox>
            <DevideView>
              <TebleText>선택</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>이름</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>휴대폰번호</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>입찰금액</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>상태</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>취소사유</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>조건</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>최종 조건</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>거래선택일</TebleText>
            </DevideView>
            <DevideView>
              <TebleText>후기</TebleText>
            </DevideView>
          </TableBox>
          <TableBody>
            {biddings.length !== 0 &&
              biddings.map((props) => (
                <BiddingStatus
                  {...props}
                  key={"Bidding_Modal" + props.id}
                  discountReason={discountReason}
                  state={state}
                  handleSelectBidding={handleSelectBidding}
                  selectBidding={selectBidding}
                />
              ))}
          </TableBody>
        </BiddingStatusBox>
      )}
    </DetialInfoWrap>
  );
}

DetailInfo.defaultProps = {
  companyName: "중고차딜러",
  bidAmount: "1050",
  condition: "타이어 교환X",
  dealDate: "2020.01.02",
};

export default DetailInfo;
