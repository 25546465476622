import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import ReactSwipe from "react-swipe";

const TableLine = styled.div`
  width: 70%;
  display: flex;
`;
const TableTitle = styled.div`
  width: 30%;
  padding: 12px 0;
  color: ${colors.darkestBlack};
  font-weight: bold;
`;
const TableInfo = styled.div`
  width: 70%;
  padding: 12px 0;
  color: ${colors.darkestBlack};
  font-weight: bold;
`;
const CarPhotoBox = styled.div`
  width: 100%;
`;
const PhotoText = styled.div`
  color: ${colors.darkestBlack};
  font-weight: bold;
  padding: 12px 0;
`;
const PhotoBox = styled.div`
  width: 400px;
  height: 220px;
`;
const Photo = styled.div`
  width: 350px;
  height: 200px;
  margin-bottom: 15px;
  border-radius: 15px;
`;
const Image = styled.img`
  object-fit: cover;
  width: 350px;
  height: 200px;
  border-radius: 15px;
`;
const ButtonBox = styled.div`
  width: 340px;
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  outline: none;
  padding: 4px 10px;
  background-color: ${colors.whiteColor};
  border: 0px;
  color: ${colors.darkerGray};
  font-weight: bold;
  font-size: 16px;
  &:active {
    opacity: 0.4;
  }
  cursor: pointer;
`;

function DetailInfoTable({
  managementNumber,
  manufacturer,
  carModel,
  carYear,
  carColor,
  fuelType,
  distance,
  carGrade,
  carNumber,
  transmission,
  description,
  discountReason,
  area,
  option,
  files = [],
}) {
  let reactSwipeEl;
  return (
    <>
      <TableLine>
        <TableTitle>제조사</TableTitle>
        <TableInfo>{manufacturer ? manufacturer : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>자동차명</TableTitle>
        <TableInfo>{carModel ? carModel : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>차량번호</TableTitle>
        <TableInfo>{carNumber ? carNumber : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>색상</TableTitle>
        <TableInfo>{carColor ? carColor : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>등급</TableTitle>
        <TableInfo>{carGrade ? carGrade : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>주행거리</TableTitle>
        <TableInfo>{distance ? `${distance} km` : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>년식</TableTitle>
        <TableInfo>{carYear ? carYear : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>연료</TableTitle>
        <TableInfo>{fuelType ? fuelType : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>변속기</TableTitle>
        <TableInfo>{transmission ? transmission : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>지역</TableTitle>
        <TableInfo>{area ? area : "모름"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>사고이력</TableTitle>
        <TableInfo>{description ? description : "없음"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>추가기재</TableTitle>
        <TableInfo>{discountReason ? discountReason : "없음"}</TableInfo>
      </TableLine>
      <TableLine>
        <TableTitle>경매관리번호</TableTitle>
        <TableInfo>{managementNumber ? managementNumber : "모름"}</TableInfo>
      </TableLine>
      <CarPhotoBox>
        <PhotoText>차량사진</PhotoText>
        {files.length !== 0 ? (
          <PhotoBox>
            <ReactSwipe
              className="carousel"
              swipeOptions={{ continuous: true }}
              childCount={files.length}
              ref={(el) => (reactSwipeEl = el)}
            >
              {files.map((props) => (
                <Photo>
                  <Image src={props.url} />
                </Photo>
              ))}
            </ReactSwipe>
            <ButtonBox>
              <Button onClick={() => reactSwipeEl.prev()}>이전</Button>
              <Button onClick={() => reactSwipeEl.next()}>다음</Button>
            </ButtonBox>
          </PhotoBox>
        ) : (
          <TableInfo>사진없음</TableInfo>
        )}
      </CarPhotoBox>
    </>
  );
}

DetailInfoTable.defaultProps = {
  manufacturer: "현대",
  carModel: "투싼",
  carNumber: "11가 1111",
  carColor: "화이트",
  carGrade: "모름",
  carYear: "2018",
  carFuel: "디젤",
  carTransmission: "오토",
  area: "수원",
  auctionNum: "2021-01-25",
};

export default DetailInfoTable;
