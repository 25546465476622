import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import ContentHead from "../../components/share/ContentHead";
import ContentTable from "../../components/feature/Home/ContentTable";
import ContentTop from "../../components/feature/Home/ContentTop";
import HomeInfoBox from "../../components/feature/Home/InfoBox";
import PasswordChange from "../../components/feature/Home/PasswordChange";
import AdminPasswordChangeModal from "../../components/feature/Home/AdminPasswordChangeModal";

const HomeWrap = styled.div`
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  padding-left: 240px;
  background-color: ${colors.emptyBackgroundColor};
`;

const ContentBox = styled.div`
  padding: 30px;
  display: flex;
  justify-content: space-between;
`;
const ContentLeft = styled.div`
  width: 70%;
  margin-right: 30px;
`;
const ContentRight = styled.div`
  width: 30%;
`;

function HomePresenter({
  dateString,
  count = 12,
  money = 4200,
  cancelBoardCount = 23,
  stopUserCount = 23,
  salesCountData,
  userCountData,
  adminData = { users: [] },
  todayData = {
    totalSales: 0,
    contents: [],
  },
   passChangeModal,
   passModalOpen,
   passModalClose,
}) {
  const { totalSales, contents } = todayData;
  const { users } = adminData;
  return (
    <HomeWrap>
      <ContentHead
        title="메인화면"
        subTitle="홈"
        bgColor={colors.skyBlue}
        subText="전체조회"
        dateString={dateString}
      />
      <PasswordChange passModalOpen={passModalOpen} />
      <ContentTop
        menuNumber="23"
        salesCountData={salesCountData}
        userCountData={userCountData}
        color={colors.activeRed}
        iconVisible={true}
        title1="총 유저수"
        title2="총 경매 매물수"
        title3="거래중 수"
        title4="경매 완료 수"
        menuNumberColor1={colors.activeRed}
        menuNumberColor2={colors.activeGreen}
        menuNumberColor3={colors.activePurple}
        menuNumberColor4={colors.activeBlue}
        barGraphVisible1={false}
        barGraphVisible2={false}
        barGraphVisible3={false}
        barGraphVisible4={false}
        barGraphColor1={colors.activeRed}
        barGraphColor2={colors.activeGreen}
        barGraphColor3={colors.activePurple}
        barGraphColor4={colors.activeBlue}
      />
      <ContentBox>
        <ContentLeft>
          <ContentTable
            dateString={dateString}
            count={contents.length}
            money={totalSales}
            contents={contents}
            cancelBoardCount={cancelBoardCount}
            stopUserCount={stopUserCount}
          ></ContentTable>
        </ContentLeft>
        <ContentRight>
          <HomeInfoBox
            bGcolor={colors.activeBlue}
            cancelCount={
              salesCountData && salesCountData.cancelCount
                ? salesCountData.cancelCount
                : 0
            }
            text="취소 된 게시글 수"
          />
          <HomeInfoBox
            bGcolor={colors.lightOrange}
            cancelCount={
              userCountData && userCountData.stopUserCount
                ? userCountData.stopUserCount
                : 0
            }
            text="정지된 회원 수"
          />
          <HomeInfoBox
            bGcolor={colors.activeGreen}
            cancelCount={
              userCountData && userCountData.adminConfirmUserCount
                ? userCountData.adminConfirmUserCount
                : 0
            }
            text="컨펌 대기"
          />
        </ContentRight>
      </ContentBox>

      <AdminPasswordChangeModal
        passChangeModal={passChangeModal}
        passInfoModalClose={passModalClose}
        adminData={users}
      />
    </HomeWrap>
  );
}

export default React.memo(HomePresenter);
