import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import { Link } from "react-router-dom";
import {
  IoIosHome,
  IoMdPerson,
  IoIosHammer,
  IoIosNotifications,
  IoIosSearch,
} from "react-icons/io";
import { useHistory } from "react-router";

const AllView = styled.div`
  position: fixed;
  left: 0;
`;
const SideBox = styled.div`
  width: 240px;
  height: 200vh;
  padding: 24px 30px;
  background-color: ${colors.whiteColor};
  box-sizing: border-box;
  position: absolute;
  left: 0%;
`;
const SideMenu = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0;
  font-size: 16px;
  color: ${colors.darkerGray};
  cursor: pointer;
  ${(props) =>
    props.active &&
    `
    font-weight: 600;
    color: ${colors.skyBlue};
  `}
  transition: 0s;
`;
const IconBox = styled.div`
  display: inline-block;
  margin-right: 20px;
  font-size: 20px;
  color: ${colors.darkerGray};
  ${(props) =>
    props.active &&
    `
    color: ${colors.skyBlue};
  `}
  transition: 0s;
`;

function SideBar({ isLoggedIn }) {
  const history = useHistory();
  const [nowPathName, setNowPathName] = useState(history.location.pathname);
  console.log("history", history);
  console.log("nowPathName", nowPathName);
  const [active, setActive] = useState({
    home: true,
    user: false,
    auction: false,
    notice: false,
    search: false,
  });

  useEffect(() => {
    switch (nowPathName) {
      case "/login":
        return setActive({
          home: false,
          user: false,
          auction: false,
          notice: false,
          search: false,
        });
      case "/home":
        return setActive({
          home: true,
          user: false,
          auction: false,
          notice: false,
          search: false,
        });
      case "/user":
        return setActive({
          home: false,
          user: true,
          auction: false,
          notice: false,
          search: false,
        });
      case "/auction":
        return setActive({
          home: false,
          user: false,
          auction: true,
          notice: false,
          search: false,
        });
      case "/notification":
        return setActive({
          home: false,
          user: false,
          auction: false,
          notice: true,
          search: false,
        });
      case "/search":
        return setActive({
          home: false,
          user: false,
          auction: false,
          notice: false,
          search: true,
        });
      default:
        setActive({
          home: true,
          user: false,
          auction: false,
          notice: false,
          search: false,
        });
    }
  }, [nowPathName]);

  const { home, user, auction, notice, search } = active;
  return (
    <>
      {isLoggedIn ? (
        <AllView>
          <SideBox>
            <Link to="/home">
              <SideMenu active={home} onClick={() => setNowPathName("/home")}>
                <IconBox active={home}>
                  <IoIosHome />
                </IconBox>
                홈
              </SideMenu>
            </Link>
            <Link to="/user">
              <SideMenu active={user} onClick={() => setNowPathName("/user")}>
                <IconBox active={user}>
                  <IoMdPerson />
                </IconBox>
                유저
              </SideMenu>
            </Link>
            <Link to="/auction">
              <SideMenu
                active={auction}
                onClick={() => setNowPathName("/auction")}
              >
                <IconBox active={auction}>
                  <IoIosHammer />
                </IconBox>
                경매
              </SideMenu>
            </Link>
            <Link to="/notification">
              <SideMenu
                active={notice}
                onClick={() => setNowPathName("/notification")}
              >
                <IconBox active={notice}>
                  <IoIosNotifications />
                </IconBox>
                공지
              </SideMenu>
            </Link>
            <Link to="/search">
              <SideMenu
                active={search}
                onClick={() => setNowPathName("/search")}
              >
                <IconBox active={search}>
                  <IoIosSearch />
                </IconBox>
                검색
              </SideMenu>
            </Link>
          </SideBox>
        </AllView>
      ) : null}
    </>
  );
}

export default SideBar;
