import React from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import SellerDetail from "./SellerDetail";
import BuyerDetail from "./BuyerDetail";
import { useMutation, useQuery } from "@apollo/client";
import { ADMIN_SEE_ALL_USER } from "../../../graphql/auction/query";
import { ADMIN_RESET_CANCEL, ADMIN_RESET_STOPPED, ADMIN_EDIT_USER } from "../../../graphql/user/mutation";
import { toast } from "react-toastify";

const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
`;
const ModalBlock = styled.div`
  width: 890px;
  height: ${(props) => (props.userType === "buyer" ? "440px" : "260px")};
  padding: 35px 45px;
  background-color: ${colors.whiteColor};
  border-radius: 28px;
`;
const ModalHeader = styled.div`
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
`;
const ModalTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkestBlack};
`;
const CloseButton = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.inactiveColor};
  cursor: pointer;
`;
const SellerDetailBox = styled.div`
  height: 100px;
`;
const BuyerDetailBox = styled.div`
  height: 100%;
`;

function UserDetailModal({
  userInfoModal,
  userInfoModalClose,
  setStopUserCategory,
  stopUserCategory,
  userType = true, // 판매자 / 구매자 유형
  selectUserData = {
    id: 0,
    stopedCount: 0,
    role: "buyer",
  },
  handleStopUser = () => null,
}) {
  const {
    adminConfirm = true,
    authImage = "없음",
    company = "없음",
    createdAt = "1613970573118",
    id = 12,
    name = "블루컴퍼니",
    phoneNumber = "01012341234",
    role = "buyer",
    stoped = false,
    stopedCount = 0,
    updatedAt = "1614068530894",
  } = selectUserData;
  console.log("selectUserData.id", selectUserData.id);
  const { data, loading, error } = useQuery(ADMIN_SEE_ALL_USER, {
    variables: {
      userId: selectUserData.id,
    },
  });
  console.log("data", data);
  console.log("apolloError", error);
  const [adminResetCancleMutation] = useMutation(ADMIN_RESET_CANCEL, {
    variables: {
      userId: id,
    },
  });

  const [adminResetCancelStoppedMutation] = useMutation(ADMIN_RESET_STOPPED, {
    variables: {
      userId: id,
    },
  });

  const [editUser] = useMutation(ADMIN_EDIT_USER);
  if (!userInfoModal) return null;

  const handelCancelReset = async () => {
    try {
      const r = window.confirm("취소 횟수를 초기화 하시겠어요?");
      if (r) {
        const result = await adminResetCancleMutation();
        if (result) {
          return toast.success("취소 초기화가 완료되었습니다.");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handelCancelResetStopped = async () => {
    try {
      const r = window.confirm("정지 횟수를 초기화 하시겠어요?");
      if (r) {
        const result = await adminResetCancelStoppedMutation();
        if (result) {
          return toast.success("취소 초기화가 완료되었습니다.");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handelEditUser = async (editName, editPhoneNumber, editRole) => {
    try {
      console.log(editName, phoneNumber, role)
      const r = window.confirm("회원 정보를 수정 하시겠어요?");
      if (r) {
        const result = await editUser({
          variables: {
            userId: id,
            name: editName,
            phoneNumber: editPhoneNumber,
            company,
            role: editRole
          },
        });
        if (result) {
          return toast.success("수정이 완료되었습니다.");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <>
      {data && data.adminSeeUser ? (
        <DarkBackground>
          <ModalBlock userType={role}>
            <ModalHeader>
              <ModalTitle>유저 상세 정보</ModalTitle>
              <CloseButton onClick={userInfoModalClose}>닫기</CloseButton>
            </ModalHeader>

            <SellerDetailBox role={role}>
              {role === "buyer" ? (
                <>
                  {data && data.adminSeeUser.biddingsCount && (
                    <SellerDetail
                      data={data.adminSeeUser.biddingsCount}
                      id={id}
                      name={name}
                      stopedCount={stopedCount}
                      phoneNumber={phoneNumber}
                      role={role}
                      setStopUserCategory={setStopUserCategory}
                      stopUserCategory={stopUserCategory}
                      handleStopUser={handleStopUser}
                      handelCancelResetStopped={handelCancelResetStopped}
                      handelEditUser={handelEditUser}
                      stoped={stoped}
                    />
                  )}
                  {role === "buyer" &&
                    data &&
                    data.adminSeeUser.buyerCancelCount && (
                      <BuyerDetailBox>
                        <BuyerDetail
                          data={data.adminSeeUser.buyerCancelCount}
                          stopedCount={stopedCount}
                          setStopUserCategory={setStopUserCategory}
                          stopUserCategory={stopUserCategory}
                          handelCancelReset={handelCancelReset}
                        />
                      </BuyerDetailBox>
                    )}
                </>
              ) : (
                <>
                  {data && data.adminSeeUser.postsCount && (
                    <SellerDetail
                      data={data.adminSeeUser.postsCount}
                      id={id}
                      name={name}
                      phoneNumber={phoneNumber}
                      role={role}
                      setStopUserCategory={setStopUserCategory}
                      stopUserCategory={stopUserCategory}
                      handleStopUser={handleStopUser}
                      handelEditUser={handelEditUser}
                      stoped={stoped}
                    />
                  )}
                </>
              )}
            </SellerDetailBox>
          </ModalBlock>
        </DarkBackground>
      ) : null}
    </>
  );
}

export default UserDetailModal;
