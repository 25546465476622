import React, {useState} from "react";
import styled from "styled-components";
import colors from "../../../styles/colors";
import { useMutation, useQuery } from "@apollo/client";
import {SEE_ADMIN_USER, SEE_ALL_USER} from "../../../graphql/user/query";
// import { toast } from "react-toastify";
import { ADMIN_PASSWORD_CHANGE_BY_ID } from "../../../graphql/user/mutation";
import useInput from "../../../hooks/useInput";
import {ADMIN_ALL_USER_COUNT} from "../../../graphql/auction/query";
import {toast} from "react-toastify";

const DarkBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
`;
const ModalBlock = styled.div`
  width: 890px;
  height: ${(props) => (props.userType === "buyer" ? "440px" : "260px")};
  padding: 35px 45px;
  background-color: ${colors.whiteColor};
  border-radius: 28px;
`;
const ModalHeader = styled.div`
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
`;
const ModalTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkestBlack};
`;
const CloseButton = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.inactiveColor};
  cursor: pointer;
`;
const PasswordChangeBox = styled.div`
  height: 100px;
`;

const AdminSelect = styled.select`
  color: ${colors.inactiveColor};
  width: 50%;
  font-size: 16px;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
const InputBox = styled.input`
  width: 350px;
  height: 48px;
  border: 1px solid ${colors.borderColor};
  border-radius: 12px;
  padding: 0 20px;
  margin-top: 10px;
  margin-left: 10px;
`;

const Button = styled.button`
  width: 80px;
  height: 40px;
  border-radius: 12px;
  text-align: center;
  padding: 4px 10px;
  float: right;
  margin-top: 20px;
  background-color: lightgreen;
  border: 0px;
  color: ${colors.darkerGray};
  font-weight: bold;
  font-size: 16px;
  &:active {
    opacity: 0.4;
  }
  cursor: pointer;
`;

function AdminPasswordChangeModal({
   adminData,
   passChangeModal,
   passInfoModalClose,
}) {
  const [selectUser, setSelectUser] = useState(null);
  const [password, setPassword] = useState('');
  const [adminPasswordChangeById] = useMutation(ADMIN_PASSWORD_CHANGE_BY_ID);
  // console.log("passChangeModal", passChangeModal);

  if (!passChangeModal) return null;

  console.log("admin _ data", adminData);
/*
  console.log(selectUser);
*/

  const handleChangePassword = async (userId, password) => {
    console.log('handleChangePassword',userId, password);
    if (userId === '선택') return alert('유저를 선택해주세요');
    if (password === '') return alert('비밀번호를 입력해주세요');
    const confirmResult = await window.confirm("비밀번호를 변경 하시겠어요?");
    if (confirmResult) {
      const result = await adminPasswordChangeById({
        variables: {
          userId: Number(userId),
          password,
        },
      });
      if (result) {
        return toast.success("비밀번호가 변경 되었습니다.");
      }
    }
  };

  const handelPassword = (event) => {
    setPassword(event.target.value);
  }
  return (
    <>
      <DarkBackground>
        <ModalBlock userType={'admin'}>
          <ModalHeader>
            <ModalTitle>비밀 번호 변경</ModalTitle>
            <CloseButton onClick={passInfoModalClose}>닫기</CloseButton>
          </ModalHeader>
          <PasswordChangeBox>
            <AdminSelect onChange={(e) => setSelectUser(e.target.value)}>
              <option value={null}>선택</option>
              { adminData.map((props) => (
                <option value={props.id}>{props.name}</option>
              )) }

            </AdminSelect>
            <InputBox
              name="userPwd"
              placeholder="비밀번호"
              type="password"
              onKeyUp={handelPassword}
            />
            <Button onClick={() => handleChangePassword(selectUser, password)}>변경</Button>
          </PasswordChangeBox>
        </ModalBlock>
      </DarkBackground>
    </>
  );
}

export default AdminPasswordChangeModal;
