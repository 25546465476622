import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import ContentHead from "../../components/share/ContentHead";
import NoticeInput from "../../components/feature/Notification/NoticeInput";
import TableBodyData from "../../components/feature/Notification/TableBodyData";
import FileInput from "../../components/feature/Notification/FileInput";

const NotificationWrap = styled.div`
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  padding-left: 240px;
  background-color: ${colors.emptyBackgroundColor};
`;
const NotificationTop = styled.div`
  padding: 30px;
  display: flex;
`;
const TopLeftInput = styled.div`
  height: 112px;
  margin-right: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
const TopRightInput = styled.div`
  height: 112px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const NotificationContent = styled.div`
  margin: 30px;
  padding: 30px;
  background-color: ${colors.whiteColor};
`;
const NotificationTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkestBlack};
`;
const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const DevideView = styled.div`
  display: flex;
  padding: 12px 15px;
  justify-content: center;
  &:first-child {
    width: 5%;
  }
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 70%;
    justify-content: start;
  }
  &:last-child {
    width: 10%;
  }
`;
const TebleText = styled.span`
  color: ${colors.inactiveColor};
`;

function NotificationPresenter({
  loading,
  accountInput,
  handleChangeAccount,
  handleChangePhoneNumber,
  data = [],
  phoneNumberInput,
  noticeData = {},
  noticeFile = [],
  handleRemoveFile = () => null,
  handleUpload = () => null,
  setFileChange,
  fileChange,
}) {
  const { adminPhoneNumber = "01028604440", account = "없음" } = noticeData;
  return (
    <NotificationWrap>
      <ContentHead
        title="공지관리"
        bgColor={colors.activeGreen}
        subTitle="팝업"
        subText="팝업관리"
        notificationVisible={true}
        totalNotice={noticeFile.length}
      />
      <NotificationTop>
        <TopLeftInput>
          <FileInput
            setFileChange={setFileChange}
            fileChange={fileChange}
            handleUpload={handleUpload}
          />
        </TopLeftInput>
        <TopRightInput>
          <NoticeInput
            placeholder={account}
            buttonText="계좌변경"
            hooksValue={accountInput}
            onPress={handleChangeAccount}
          />
          <NoticeInput
            placeholder={adminPhoneNumber}
            buttonText="번호"
            onPress={handleChangePhoneNumber}
            hooksValue={phoneNumberInput}
          />
        </TopRightInput>
      </NotificationTop>

      <NotificationContent>
        <NotificationTitle>총 공지글</NotificationTitle>
        <TableHeader>
          <DevideView>
            <TebleText>NO</TebleText>
          </DevideView>
          <DevideView>
            <TebleText>업로드 날짜</TebleText>
          </DevideView>
          <DevideView>
            <TebleText>내용</TebleText>
          </DevideView>
          <DevideView>
            <TebleText>지우기</TebleText>
          </DevideView>
        </TableHeader>
        {noticeFile.length !== 0 &&
          noticeFile.map((props, index) => (
            <TableBodyData
              {...props}
              key={"NoticeFile" + index}
              number={index + 1}
              onPress={handleRemoveFile}
            />
          ))}
      </NotificationContent>
    </NotificationWrap>
  );
}

export default NotificationPresenter;
