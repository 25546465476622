import { gql } from "@apollo/client";

export const SEE_ALL_USER = gql`
  query seeAllUser(
    $take: Int
    $cursor: Int
    $adminConfirm: Boolean
    $role: Role
    $stoped: Boolean
  ) {
    seeAllUser(
      take: $take
      cursor: $cursor
      adminConfirm: $adminConfirm
      role: $role
      stoped: $stoped
    ) {
      users {
        id
        createdAt
        updatedAt
        name
        phoneNumber
        company
        role
        authImage
        stoped
        stopedCount
        adminConfirm
      }
      userCount
      preCursor
    }
  }
`;

export const SEE_ADMIN_USER = gql`
  query seeAllAdmin($take: Int, $cursor: Int) {
    seeAllAdmin(take: $take, cursor: $cursor) {
      users {
        id
        createdAt
        updatedAt
        name
        phoneNumber
        company
        role
        authImage
        stoped
        stopedCount
        adminConfirm
      }
      userCount
      preCursor
    }
  }
`;
