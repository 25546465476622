import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import ContentHead from "../../components/share/ContentHead";
import TableBodyData from "../../components/feature/User/TableBodyData";
// import SmsButton from "../../components/share/SmsButton";
import UserMessageModal from "../../components/feature/User/UserMessageModal";
import UserDetailModal from "../../components/feature/User/UserDetailModal";
import ContentsHeader from "../../components/share/ContentsHeader";
import CounterBlock from "../../components/share/CounterBlock";

const UserWrap = styled.div`
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  padding-left: 240px;
  background-color: ${colors.emptyBackgroundColor};
`;
const UserTableWrap = styled.div`
  margin: 30px;
  padding: 21px 24px;
  background-color: ${colors.whiteColor};
`;
const TableTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TableTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.darkestBlack};
`;
const TableHeader = styled.div`
  display: flex;
  margin-top: 20px;
`;

function UserPresenter({
                           messageModal,
                           messageModalOpen,
                           messageModalClose,
                           stopUserModal,
                           stopUserModalOpen,
                           stopUserModalClose,
                           userInfoModal,
                           userInfoModalOpen,
                           userInfoModalClose,
                           modalEl,
                           data = {
                               user: [],
                               userCount: 0,
                           },
                           onClickSmsCheck = () => null,
                           smsList = [],
                           messageInput,
                           sendMessage,
                           setUserCategory,
                           adminCountData = 0,
                           excelData = { excelData: "없음" },
                           handleStopUser = () => null,
                           setStopUserCategory = () => null,
                           userCategory,
                           stopUserCategory,
                           confirmUserCategory,
                           setConfirmUserCategory,
                           selectUserData = {},
                           setSelectUserData = () => null,
                           handleConfirmUser = () => null,
                           setSeeAllUserCondition = () => null,
                           excelLoading = false,
                           smsAllCheck = false,
                           handleSmsAllCheck = () => null,
                       }) {
    const { users = [], userCount = 0 } = data;
    return (
        <UserWrap>
            <ContentHead
                title="유저화면"
                subTitle="회원"
                bgColor={colors.activeRed}
                subText="유저관리"
                userVisible={true}
                totalUsers={userCount}
                excelData={excelData}
                excelLoading={excelLoading}
            />
            <CounterBlock
                {...adminCountData}
                setUserCategory={setUserCategory}
                setStopUserCategory={setStopUserCategory}
                userCategory={userCategory}
                stopUserCategory={stopUserCategory}
                confirmUserCategory={confirmUserCategory}
                setConfirmUserCategory={setConfirmUserCategory}
                setSeeAllUserCondition={setSeeAllUserCondition}
            />

            <UserTableWrap>
                <TableTitleBox>
                    <TableTitle>
                        회원통계 {data.length !== 0 && `/ ${data.userCount} 명`}
                    </TableTitle>
                    {/*<SendBox>*/}
                    {/*  <SmsButton*/}
                    {/*    messageModalOpen={handleSmsAllCheck}*/}
                    {/*    titie="전체 문자 보내기"*/}
                    {/*  />*/}
                    {/*  <SmsButton messageModalOpen={messageModalOpen} />*/}
                    {/*</SendBox>*/}
                </TableTitleBox>

                <TableHeader>
                    <ContentsHeader
                        // selectOption={["구분 (전체)", "판매자", "구매자"]}
                        setUserCategory={setUserCategory}
                        headerTitle={[
                            "구분",
                            "가입일",
                            "이름",
                            "휴대폰번호",
                            "사원증/사업자",
                            "정지상태",
                            "횟수",
                            "인증",
                        ]}
                    />
                </TableHeader>
                {/* User Data */}
                {users.length !== 0 &&
                    users.map((props) => (
                        <TableBodyData
                            userInfoModalOpen={userInfoModalOpen}
                            stopUserModalOpen={stopUserModalOpen}
                            onClickSmsCheck={onClickSmsCheck}
                            {...props}
                            key={"userId" + props.id}
                            handleStopUser={handleStopUser}
                            userData={props}
                            setSelectUserData={setSelectUserData}
                            handleConfirmUser={handleConfirmUser}
                            smsAllCheck={smsAllCheck}
                            messageModal={messageModal}
                        />
                    ))}
            </UserTableWrap>

            {/* Modal */}
            <UserMessageModal
                messageModal={messageModal}
                messageModalClose={messageModalClose}
                ref={modalEl}
                smsList={smsList}
                messageInput={messageInput}
                sendMessage={sendMessage}
                handleStopUser={handleStopUser}
            />
            <UserDetailModal
                userInfoModal={userInfoModal}
                userInfoModalClose={userInfoModalClose}
                setStopUserCategory={setStopUserCategory}
                stopUserCategory={stopUserCategory}
                selectUserData={selectUserData}
                handleStopUser={handleStopUser}
            />
        </UserWrap>
    );
}

export default React.memo(UserPresenter);
