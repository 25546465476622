import React from "react";
import styled, { css } from "styled-components";
import colors from "../../styles/colors";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";

const InputWrap = styled.div`
  width: 273px;
  height: 40px;
  background: ${colors.whiteColor};
  border-radius: 4px;
  padding-left: 10px;
`;
const SearchIcon = styled.span`
  color: ${colors.borderColor};
  font-size: 24px;
  line-height: 0;
  vertical-align: middle;
`;
const InputBox = styled.input`
  width: 90%;
  height: 100%;
  border: none;
  padding-left: 10px;
  &::placeholder {
    color: ${colors.borderColor};
  }
`;

function Input({ searchInput = "" }) {
  return (
    <Link to="/search">
      <InputWrap>
        <SearchIcon>
          <BiSearch />
        </SearchIcon>
        <InputBox placeholder="검색하기" {...searchInput} />
      </InputWrap>
    </Link>
  );
}

export default Input;
