import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import styled from "styled-components";
import { NOTICE_FILE_CREATE } from "../../../graphql/notice/mutation";
import colors from "../../../styles/colors";
import axios from "axios";
import { toast } from "react-toastify";
import { SEE_NOTICE_FILE } from "../../../graphql/notice/query";
import ServerIP from "../../../apollo/ServerIP";

const InputBox = styled.div`
  width: 365px;
  height: 45px;
  display: flex;
`;
const FileLabel = styled.label`
  width: calc(100% - 120px);
  color: ${colors.grayColor};
  padding-left: 20px;
  display: flex;
  align-items: center;
  background: ${colors.whiteColor};
  cursor: pointer;
`;
const Input = styled.input`
  display: none;
`;
const Button = styled.button`
  width: 120px;
  height: 45px;
  color: ${colors.whiteColor};
  border: none;
  background-color: ${colors.activeBlue};
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const Form = styled.div`
  form {
    display: flex;
    input {
      &:not(:last-child) {
      }
      width: 225px;
      border: 0px;
      padding-top: 8px;
    }
    button {
      width: 120px;
      height: 45px;
      color: ${colors.whiteColor};
      border: none;
      background-color: ${colors.activeBlue};
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
`;
function FileInput() {
  const [fileChange, setFileChange] = useState("");
  const [fileUploadMutation] = useMutation(NOTICE_FILE_CREATE);

  console.log(fileChange);

  const fileUpload = (_file) => {
    // const url = "http://61.83.147.71:9755/api/adminImageUpload";
    // const url = "https://api.crigging.shop/api/adminImageUpload";
    const url = "https://api.bluecompany.kr/api/adminImageUpload";
    console.log("이거 유알엘 업로드", url);
    const formData = new FormData();
    formData.append("file", _file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  const handleUpload = async () => {
    if (fileChange === "") {
      toast.error("파일을 선택해주세요");
    }
    try {
      const response = await fileUpload(fileChange);
      if (response) {
        const result = fileUploadMutation({
          variables: {
            noticeImage: response.data[0],
          },
          refetchQueries: [
            {
              query: SEE_NOTICE_FILE,
            },
          ],
        });
        if (result) {
          return toast("파일 업로드가 완료되었습니다.");
        }
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };

  return (
    <InputBox>
      <FileLabel>
        <Form>
          <form onSubmit={handleUpload}>
            <input
              type="file"
              accept="image/*"
              placeholder="파일 가져오기"
              onChange={(e) => setFileChange(e.target.files[0])}
            />
            <button type="button" onClick={handleUpload}>
              업로드
            </button>
          </form>
        </Form>
      </FileLabel>
    </InputBox>
  );
}

export default FileInput;
