import React, { useState } from "react";
import colors from "../../styles/colors";
import styled from "styled-components";
import CountUp from "react-countup";

const View = styled.div`
  width: 100%;
  display: flex;
  padding: 24px 30px 30px 30px;
`;

const MenuInfoBox = styled.div`
  width: 25%;
  height: 114px;
  color: ${colors.darkerGray};
  padding: 10px;
  background: ${(props) =>
    props.state ? `${colors.activeBlue}` : `${colors.whiteColor}`};
  ${(props) =>
    props.borderRight &&
    `border-right: 1px solid ${colors.emptyBackgroundColor};`}
  ${(props) => (props.state ? `cursor: pointer;` : `cursor: pointer;`)}
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const MenuNumber = styled.span`
  font-size: 44px;
  color: ${(props) => (props.color ? props.color : `${colors.darkestBlack}`)};
  ${(props) => props.state && `color: ${colors.whiteColor};`}
`;
const MenuInfo = styled.div`
  display: inline-block;
  margin-bottom: 17px;
`;

const MenuText = styled.span`
  display: block;
  margin-top: 22px;
  font-size: 18px;
  color: ${(props) => (props.color ? props.color : `${colors.darkestGray}`)};
  ${(props) => props.state && `color: ${colors.whiteColor};`}
`;

const CounterBlock = ({
  menuNumber = 12,
  adminAllUserCount = {
    allUserCount: 0,
    adminConfirmUserCount: 0,
    sellerCount: 0,
    buyerCount: 0,
    stopUserCount: 0,
  },
  setUserCategory = () => null,
  setStopUserCategory = () => null,
  userCategory = false,
  stopUserCategory = false,
  confirmUserCategory,
  setConfirmUserCategory,
  setSeeAllUserCondition,
}) => {
  const {
    allUserCount,
    adminConfirmUserCount,
    sellerCount,
    buyerCount,
    stopUserCount,
  } = adminAllUserCount;
  const [activeState, setActiveState] = useState({
    total: true,
    seller: false,
    buyer: false,
    waitConfirm: false,
    stopUser: false,
  });
  console.log("액티스 스태이트", activeState);
  return (
    <View>
      <MenuInfoBox
        borderRight
        state={activeState.total}
        onClick={
          () => {
            setSeeAllUserCondition({
              role: "all",
              stoped: false,
              adminConfirm: false,
            });
            setActiveState({
              total: true,
              seller: false,
              buyer: false,
              waitConfirm: false,
              stopUser: false,
            });
          }
          // setUserCategory("all");
        }
      >
        <MenuInfo>
          <MenuText state={activeState.total}>전체</MenuText>
        </MenuInfo>
        <MenuNumber state={activeState.total}>
          <CountUp end={allUserCount} />
        </MenuNumber>
      </MenuInfoBox>
      <MenuInfoBox
        borderRight
        state={activeState.seller}
        onClick={() => {
          setSeeAllUserCondition({
            role: "seller",
            stoped: false,
            adminConfirm: false,
          });
          setActiveState({
            total: false,
            seller: true,
            buyer: false,
            waitConfirm: false,
            stopUser: false,
          });
        }}
      >
        <MenuInfo>
          <MenuText state={activeState.seller}>딜러</MenuText>
        </MenuInfo>
        <MenuNumber state={activeState.seller}>
          <CountUp end={sellerCount} />
        </MenuNumber>
      </MenuInfoBox>
      <MenuInfoBox
        borderRight
        state={activeState.buyer}
        onClick={
          () => {
            setSeeAllUserCondition({
              role: "buyer",
              stoped: false,
              adminConfirm: false,
            });
            setActiveState({
              total: false,
              seller: false,
              buyer: true,
              waitConfirm: false,
              stopUser: false,
            });
          }
          // setUserCategory("buyer");
        }
      >
        <MenuInfo>
          <MenuText state={activeState.buyer}>수출사업자</MenuText>
        </MenuInfo>
        <MenuNumber state={activeState.buyer}>
          <CountUp end={buyerCount} />
        </MenuNumber>
      </MenuInfoBox>
      <MenuInfoBox
        borderRight
        state={activeState.waitConfirm}
        onClick={() => {
          setSeeAllUserCondition({
            role: "all",
            stoped: false,
            adminConfirm: true,
          });
          setActiveState({
            total: false,
            seller: false,
            buyer: false,
            waitConfirm: true,
            stopUser: false,
          });
          // setUserCategory("buyer");
        }}
      >
        <MenuInfo>
          <MenuText state={activeState.waitConfirm}>인증대기자</MenuText>
        </MenuInfo>
        <MenuNumber state={activeState.waitConfirm}>
          <CountUp end={adminConfirmUserCount} />
        </MenuNumber>
      </MenuInfoBox>
      <MenuInfoBox
        borderRight
        state={activeState.stopUser}
        onClick={() => {
          setSeeAllUserCondition({
            role: "all",
            stoped: true,
            adminConfirm: false,
          });
          setActiveState({
            total: false,
            seller: false,
            buyer: false,
            waitConfirm: false,
            stopUser: true,
          });
          // setUserCategory("buyer");
        }}
      >
        <MenuInfo>
          <MenuText state={activeState.stopUser}>정지자</MenuText>
        </MenuInfo>
        <MenuNumber state={activeState.stopUser}>
          <CountUp end={stopUserCount} />
        </MenuNumber>
      </MenuInfoBox>
      {/* <ConfirmInfoBox
        borderRight
        onClick={() => setConfirmUserCategory(!confirmUserCategory)}
        state={confirmUserCategory}
      >
        <ConfirmInfo>
          <ConfirmText state={confirmUserCategory}>인증대기</ConfirmText>
        </ConfirmInfo>
        <ConfirmNumber state={confirmUserCategory}>
          <CountUp end={adminConfirmUserCount} />
        </ConfirmNumber>
      </ConfirmInfoBox>
      <StopInfoBox
        onClick={() => setStopUserCategory(!stopUserCategory)}
        state={stopUserCategory}
      >
        <StopInfo>
          <StopText state={stopUserCategory}>정지자</StopText>
        </StopInfo>
        <StopNumber state={stopUserCategory}>
          <CountUp end={stopUserCount} />
        </StopNumber>
      </StopInfoBox> */}
    </View>
  );
};

export default React.memo(CounterBlock);
